
import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { LocalStorage } from "../core/localStorage";
import { Format } from "../core/helpers/formatHelper";
import { ShopService } from "../core/services/shopService";
import store from "../core/store";

@Component({
  components: {},
})
export default class ProductPage extends Vue {
  product: any = null;
  popular: any = null;

  error: string = "";

  async mounted() {
    await this.loadData();
  }

  addToCart(p: any, e: any) {
    e.preventDefault();
    new ShopService().addProductToCart(p, 1, e.currentTarget);
  }

  async loadData() {
    let cid = null;
    let sid = LocalStorage.getWithTimeout("cc");
    if (sid !== undefined && sid !== null) {
      cid = JSON.parse(sid).id;
    }

    let url =
      cid == null
        ? `${Settings.HostName}/api/shop/get/${this.$route.params.id}`
        : `${Settings.HostName}/api/shop/get/${this.$route.params.id}/${cid}`;
    let data = await new CrudService().httpGet(url);

    if (data == null || data === undefined || data.ok === false) {
      console.error("Crash getting shop " + cid);
      return;
    }

    this.product = data.data;
    this.popular = data.popular;
  }

  getImagePath(img: string) {
    if (img === null || img === undefined || img === "") {
      return store.state.settings.Uploads + "/" + store.state.settings.Mainlogo;
    }
    return store.state.settings.Uploads + "/" + img;
  }

  formatPrice(pr: any) {
    var p = store.state.settings.PriceWithTaxes ? pr.grossPrice : pr.netPrice;
    return Format.formatCurrency(p);
  }
}
