// is server definition
export enum InputType {
  Label,
  Href,
  Text,
  Date,
  CheckBox,
  Image,
  Picklist,
  PickListLabel,
  Lookup,
  TextArea,
  Password,
  LabelPassword,
  Email,
  Currency,
  FileLoader,
  ImageLoader,
  Hidden,
  Html,
  HtmlText,
  HourPicker,
  ColorPicker,
  ColorLabel,
  DateNoTime
}