import { Settings } from "../services/configService";
import store from "../store";
import { Format } from "./formatHelper";
import { LocaleHelper } from "./localeHelper";

export class DateHelper {

    public static systemFormat = "yyyy-MM-dd HH:mm";

    private static getCulture(): string {
        return LocaleHelper.getStoredCulture() ?? store.state.settings.Culture;
    }

    static toServerFormat(input: string | Date, format?: string) {
        if (input instanceof Date) {
            return DateHelper.toSystemString(input);
        }
        return DateHelper.toSystemString(DateHelper.toDate(input, format));
    }

    // input must be in system format
    static toCultureString(input: string): string {
        const date: any = this.toDate(input, this.systemFormat);
        return this.formatDate(date);
    }

    // input must be in system format
    static toSystemDate(input: string) {
        return this.toDate(input, this.systemFormat);
    }

    static toCultureTimeString(date: Date) {
        const culture = this.getCulture();
        return date.toLocaleTimeString(culture, {
            hour: "2-digit",
            minute: "2-digit",
        });
    }

    static toDate(input: string, format: string = "") {
        if (Format.IsNull(input)) {
            return;
        }
        if (format == "") {
            format = String(localStorage.getItem(window.location.host + "_dateFormat"));
        }

        if (input == null || input == "") return;
        const formatParts = format.match(/(\w+)/g);
        if (formatParts == null) return;
        const valueParts = input.match(/(\d+)/g);
        if (valueParts == null) return;

        let day: number = 0, month: number = 0, year: number = 0, hour: number = 0, min: number = 0;

        for (let i = formatParts.length - 1; i >= 0; i--) {
            switch (formatParts[i]) {
                case "dd":
                case "d":
                    day = parseInt(valueParts[i]);
                    break;

                case "M":
                case "MM":
                    month = parseInt(valueParts[i]) - 1; // months starts at 0
                    break;

                case "yyyy":
                case "yy":
                    year = parseInt(valueParts[i]);
                    if (year < 100) {
                        year += 2000;
                    }
                    break;

                case "mm":
                    min = valueParts.length > i ? parseInt(valueParts[i]) : 0;
                    break;

                case "H":
                case "HH":
                    hour = valueParts.length > i ? parseInt(valueParts[i]) : 0;
                    break;
            }
        }

        return new Date(year, month, day, hour, min);
    }

    static toSystemString(date?: Date | null) {
        return this.formatDate(date, this.systemFormat);
    }

    /// Date to string
    static formatDate(date?: Date | null, format: string = "") {
        if (date == undefined || date == null) {
            console.error("date in formatDate is null");
            return "";
        }
        if (!format) {
            format = store.state.settings.DateFormat ? store.state.settings.DateFormat : this.systemFormat;
        }

        let result = format;

        if (format.indexOf("yyyy") != -1) {
            result = result.replace("yyyy", date.getFullYear().toString());
        }
        else if (format.indexOf("yy") != -1) {
            result = result.replace("yy", date.getFullYear().toString());
        }

        const month = this.pad0(date.getMonth() + 1);
        if (format.indexOf("MM") != -1) {
            result = result.replace("MM", month);
        }
        else if (format.indexOf("M") != -1) {
            result = result.replace("M", month);
        }

        const day = date.getDate();
        const strDay = this.pad0(day);

        if (format.indexOf("dd") != -1) {
            result = result.replace("dd", strDay);
        }
        else if (format.indexOf("d") != -1) {
            result = result.replace("d", strDay);
        }

        let hour = this.pad0(date.getHours());
        if (format.indexOf("HH") != -1) {
            result = result.replace("HH", hour);
        } else if (format.indexOf("H") != -1) {
            result = result.replace("H", hour);
        } else if (format.indexOf("h") != -1) {
            const dHours = date.getHours();
            if (dHours > 12) {
                hour = (dHours - 12).toString();
                result = result.replace("tt", "pm");
            } else {
                result = result.replace("tt", "am");
            }
            result = result.replace("h", hour);
        }
        if (format.indexOf("mm") != -1) {
            const min = this.pad0(date.getMinutes());
            result = result.replace("mm", min);
        }

        return result;
    };

    private static pad0(input: number) {
        let inputStr = input.toString();
        if (input < 10) {
            inputStr = 0 + inputStr;
        }
        return inputStr;
    }

    static addMinutesToHourString(time: string, minsToAdd: number) {
        function D(J) { return (J < 10 ? '0' : '') + J; };
        const piece: any = time.split(':'); // todo: find separator by culture
        const mins = piece[0] * 60 + +piece[1] + +minsToAdd;

        return D(mins % (24 * 60) / 60 | 0) + ':' + D(mins % 60);
    }


    static dateAdd(date: Date, interval: string, units: number) {
        if (date == null) return;

        const ret = new Date(date); //don't change original date
        const checkRollover = function () { if (ret.getDate() != date.getDate()) ret.setDate(0); };
        switch (String(interval).toLowerCase()) {
            case 'year': ret.setFullYear(ret.getFullYear() + units); checkRollover(); break;
            case 'quarter': ret.setMonth(ret.getMonth() + 3 * units); checkRollover(); break;
            case 'month': ret.setMonth(ret.getMonth() + units); checkRollover(); break;
            case 'week': ret.setDate(ret.getDate() + 7 * units); break;
            case 'day': ret.setDate(ret.getDate() + units); break;
            case 'hour': ret.setTime(ret.getTime() + units * 3600000); break;
            case 'minute': ret.setTime(ret.getTime() + units * 60000); break;
            case 'second': ret.setTime(ret.getTime() + units * 1000); break;
            default: return null;
        }
        return ret;
    }

    static addSeconds(date: Date, secs: number) {
        return this.dateAdd(date, 'second', secs);
    }

    static addMinutes(date: Date, mins: number) {
        return this.dateAdd(date, 'minute', mins);
    }

    static addHours(date: Date, hours: number) {
        return this.dateAdd(date, 'hour', hours);
    }

    static addDays(date: Date, days: number) {
        return this.dateAdd(date, 'day', days);
    }

    static addWeeks(date: Date, weeks: number) {
        return this.dateAdd(date, 'week', weeks);
    }

    static addMonths(date: Date, months: number) {
        return this.dateAdd(date, 'month', months);
    }

    static addYears(date: Date, years: number) {
        return this.dateAdd(date, 'year', years);
    }

    // checks if date 2 is full contained in date1
    static IsContained(start1: Date, end1: Date, start2: Date, end2): boolean {
        if (start1 <= start2 && end2 <= end1) {
            return true;
        }
        return false;

    }

    static isInDayOfWeek(date: Date | undefined, dayOfWeek: number) {
        if (date === undefined) {
            return false;
        }
        const dateDayOfWeek = date.getDay();
        return dateDayOfWeek === dayOfWeek;
    }

    static getTextMonth(Vue: any, month: number) {
        switch (month) {
            case 0: return Vue.$t("January");
            case 1: return Vue.$t("February");
            case 2: return Vue.$t("March");
            case 3: return Vue.$t("April");
            case 4: return Vue.$t("May");
            case 5: return Vue.$t("June");
            case 6: return Vue.$t("July");
            case 7: return Vue.$t("August");
            case 8: return Vue.$t("September");
            case 9: return Vue.$t("October");
            case 10: return Vue.$t("November");
            case 11: return Vue.$t("December");
        }
    }

    static getDaysBetweenDates(date1: Date, date2: Date) {
        // Convert the dates to milliseconds
        const date1Ms = date1.getTime();
        const date2Ms = date2.getTime();

        // Calculate the difference in milliseconds
        const differenceMs = Math.abs(date2Ms - date1Ms);

        // Convert the difference to days
        const days = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

        return days;
    }

    static beautifyDate(date: string, showWeekDay: boolean = true, showYear: boolean = true, shortMonth: boolean = false, showTime: boolean = true) {
        const options: any = {
            //weekday: "long",
            // year: "numeric",
            month: shortMonth ? "short" : "long",
            day: "numeric",
            // hour: 'numeric',
            // minute: 'numeric'
        };
        if (showWeekDay === true) {
            options.weekday = "long";
        }

        if (showYear === true) {
            options.year = "numeric";
        }

        if (showTime) {
            options.hour = 'numeric';
            options.minute = 'numeric';
        }

        const culture = this.getCulture();
        const str = new Date(date).toLocaleString(culture, options);
        return str;
    }
}




