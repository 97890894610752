
import Component from '@modules/vue-class-component';
import { Prop } from '@modules/vue-property-decorator/lib';
import Vue from 'vue';

@Component({})
export default class RangeBar extends Vue {
    @Prop({ required: true, default: [0, 1]}) validRange!: [number, number];
    @Prop() currentRange!: [number, number];

    private get validRangeDiff(): number {
        return this.validRange[1] - this.validRange[0];
    }

    private get currentMinRatio() {
        return this.currentRange[0]/this.validRangeDiff;
    }

    private get currentMaxRatio() {
        return this.currentRange[1]/this.validRangeDiff;
    }

    private get globalStyleVariables() {
        return {
            '--current-min-ratio': this.currentMinRatio,
            '--current-max-ratio': this.currentMaxRatio,
        };
    }

}
