
import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { LocalStorage } from "../core/localStorage";
import CancelReturnsInfo from "../components/cancelReturnsInfoCz/cancelReturnsInfoCz.vue";
import TriggerAnchor from "../components/triggerAnchor/triggerAnchor.vue";
import { ParentNameAcademy, ParentNameBooking } from "../core/constants";
import { nextTick } from "@modules/vue/types/umd";
import { RouterLink } from "@modules/vue-router/types";

interface OpenFacilityResponse {
  content: string;
  contentType: string;
  encoding: string;
}

@Component({
  components: {
    CancelReturnsInfo,
    TriggerAnchor,
  },
} as any)
export default class MyBookingsPage extends Vue {
  data: any = null;
  trainings: any = null;
  errorMsg: string = "";
  infoReturns: any = null;
  qrs: { [id: number]: OpenFacilityResponse } = {};
  reload: number = 1;
  copied: any = {};

  isPublic: boolean = false;
  bookingToken: string | null = null;

  async mounted() {
    let cid = 0;

    this.isPublic = this.$route.meta?.public === true;

    this.bookingToken = this.$route.params.bookingToken;

    if (this.isPublic !== true) {
      try {
        cid = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
      } catch {
        console.error("not customer logged found");
      }
    }

    await this.loadData(cid);
  }

  async loadTurntilesStatus() {
    const url = `${Settings.HostName}/api/publicturnstiles/enabled`;
    const areEnabled = await new CrudService().httpGet(url);
    return areEnabled === true;
  }

  async loadData(customerId: number) {
    let url = `${Settings.HostName}/api/publicbookings/customer-bookings/${customerId}`;
    if (this.isPublic === true) {
      url = `${Settings.HostName}/api/publicbookings/customer-booking/${this.bookingToken}`;
    }

    let data = await new CrudService().httpGet(url);
    if (data == null || data.ok === false) {
      console.error("Crash getting my bookings");
      return;
    }
    this.data = { turntilesEnabled: false };

    this.data.next = data.model.next.filter(
      (y) => y.parentName !== ParentNameAcademy
    );
    this.data.past = data.model.past.filter(
      (y) => y.parentName !== ParentNameAcademy
    );
    this.trainings = {};
    this.trainings.next = data.model.next.filter(
      (y) => y.parentName === ParentNameAcademy
    );
    this.trainings.past = data.model.past.filter(
      (y) => y.parentName === ParentNameAcademy
    );
    const turntilesEnabled = await this.loadTurntilesStatus();
    this.data.turntilesEnabled = turntilesEnabled;
    
    setTimeout(() => {
      if (this.data.next.length === 0 && this.trainings.next.length !== 0) {
        document.getElementById("tab-trainings").click();
      }
    }, 20);
  }

  async cancel(id: number, e: any) {
    this.errorMsg = "";
    e.preventDefault();
    let cid = 0;
    try {
      cid = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
    } catch {
      console.error("not customer logged found");
    }

    const url = `${Settings.HostName}/api/publicbookings/cancel/${id}/${cid}`;
    let data = await new CrudService().httpPost(url);
    if (data == null) {
      console.error("Crash cancelling my bookings");
      return;
    }

    if (data.errorMsg !== null && data.errorMsg !== undefined) {
      this.errorMsg = data.errorMsg;
      return;
    }

    this.infoReturns = data.infoReturns;
    this.reload += 1;

    return await this.loadData(cid);
  }

  async openFacility(id: number, e: any) {
    let url = `${Settings.HostName}/api/publicturnstiles/open/${id}`;
    if (this.$route.meta?.public === true) {
      url = `${Settings.HostName}/api/publicturnstiles/open/${id}/${this.$route.params.bookingToken}`;
    }
    const res = await new CrudService().httpGet(url);
    if (res.ok !== true) {
      this.errorMsg = res.reason;
    } else {
      Vue.set(this.qrs, id, res.model);
    }
  }

  bookingUrl(id: number) {
    return `${location.origin}/booking-join/${id}`;
  }

  copyToClipBoard(id: number) {
    navigator.clipboard.writeText(this.bookingUrl(id));
    this.$set(this.copied, id, true);
    setTimeout(() => {
      this.copied = [];
    }, 2000);
  }
}
