
import { Component, Vue } from "vue-property-decorator";
import store from "../../core/store";

@Component({
  components: {},
} as any)
export default class Sponsors extends Vue {
  data: any[] = [];

  async mounted() {
    const rClubs = store.state.settings.relatedClubs;
    if (rClubs == null) {
      return;
    }
    this.data = rClubs;
  }
}
