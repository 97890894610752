
import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { Format } from "../core/helpers/formatHelper";
import store from "../core/store";
import { BookingConcept } from "@shared/crm/constants";

@Component
export default class BookingTypesPage extends Vue {
  dbBookingTypes: any = null;
  bookingTypes: any = null;
  errorMsg: string = "";
  categories: string[] = [];
  camelCase: any = Format.toCamelCase;

  async mounted() {
    const url = `${Settings.HostName}/api/publicbookings/booking-types-list`;
    const data = await new CrudService().httpGet(url);
    if (data == null || data.model == null || !data.ok) {
      console.error("Crash getting index booking types");
      return;
    }

    this.bookingTypes = data.model;
    
    const queryConceptMap = new Map<string, Number>([
      ['bookings', BookingConcept.Booking],
      ['matches', BookingConcept.Match],
      ['school', BookingConcept.School],
      ['events', BookingConcept.Event]
    ]);

    var queries = Object.keys(this.$route.query);
    if (queries.length > 0) {
      const concept = queryConceptMap.get(Object.keys(this.$route.query)[0])
      this.bookingTypes = this.bookingTypes.filter(y => y.concept === concept);
    }

    this.dbBookingTypes = data.model;
    if (this.dbBookingTypes.length === 1) {
      this.$router.push(this.buildUrlBooking(this.dbBookingTypes[0]));
      return;
    }

    this.setUpCategories();
  }

  setUpCategories() {
    for (const bt of this.dbBookingTypes) {
      if (bt.category === null || bt.category === undefined) {
        continue;
      }

      if (
        this.categories.find(
          (y) => y.toLowerCase() == bt.category.toLowerCase()
        ) == undefined
      ) {
        this.categories.push(bt.category);
      }
    }
  }

  getImagePath(img: string) {
    if (img === null || img === undefined || img === "") {
      return (
        this.$store.state.settings.Uploads +
        "/" +
        this.$store.state.settings.Mainlogo
      );
    }
    return this.$store.state.settings.Uploads + "/" + img;
  }

  buildUrlBooking(bt: any) {
    if (bt.bookingByPlace === true) {
      return `/bookings-byplace/${bt.id}`;
    }
    return `/bookings/${bt.id}`;
  }

  filterByCategory(cat: string, e: any) {
    e.preventDefault();
    if (cat === "") {
      this.bookingTypes = this.dbBookingTypes;
      let dom: any = document.getElementById(`filter_all`);
      dom.classList.add("active");
      return;
    }

    this.bookingTypes = this.dbBookingTypes.filter(
      (y) =>
        y.category !== null && y.category.toLowerCase() === cat.toLowerCase()
    );
    for (const allcat of this.categories) {
      let domCat: any = document.getElementById(`filter_${allcat}`);
      domCat.classList.remove("active");
    }
    let domCat: any = document.getElementById(`filter_all`);
    domCat.classList.remove("active");

    let dom: any = document.getElementById(`filter_${cat}`);
    dom.classList.add("active");
  }

  getPrice(pr: any) {
    return this.formatText(
      store.state.settings.PriceWithTaxes ? pr.grossPrice : pr.netPrice
    );
  }

  formatText(amount: string | null) {
    if (amount == null) {
      return "   ";
    }
    return this.$t("From") + ' ' + Format.formatCurrency(amount as string);
  }
}
