
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { Settings, TenantSettings } from "../../core/services/configService";
import store from "../../core/store";
import CheckBox from "../form/checkBox.vue";
import TextArea from "../form/textArea.vue";
import InputDate from "../form/inputDate.vue";
import InputPassword from "../form/inputPassword.vue";
import InputSelect from "../form/inputSelect.vue";
import InputText from "../form/inputText.vue";
import InputFile from "../form/inputFile.vue";
import LookupResults from "../form/lookupResults.vue";
import Popup from "../popup/popup.vue";
import Popover from "../popover/popover.vue";
import { InputType } from "./inputTypes";
import { CrudService } from "../../core/services/crudService";
import { Format } from "../../core/helpers/formatHelper";

@Component({
  components: {
    CheckBox,
    InputDate,
    InputPassword,
    InputSelect,
    InputText,
    Popover,
    TextArea,
    InputFile,
    LookupResults,
    Popup,
  },
})
export default class DynamicField extends Vue {
  @Prop() fdata!: any;
  @Prop() label!: string;
  @Prop() errors!: string[];

  private inputTypes = InputType;

  private search = "";
  private searchResults: any[]|null = null;

  private get settings(): TenantSettings {
    return store.state.settings;
  }

  created() {
    if (this.fdata.editInputType === this.inputTypes.Picklist) {
      this.fdata.value = -1;
    }
  }

  @Emit("input")
  private emitValue(value: any) {
    return value;
  }

  //#region LOOKUP
  private get fkLabel() {
    return Format.toLowerFirst(
      this.fdata.floatingFilterComponentParams.fkLabel
    );
  }

  private get fkId() {
    return Format.toLowerFirst(
      this.fdata.floatingFilterComponentParams.fkId
    );
  }

  private selectResult(result: any) {
    const text = result[this.fkLabel];
    const id = result[this.fkId];
    this.search = text;
    this.fdata.value = id;
    this.searchResults = null;
    this.emitValue(id);
  }

  private getLabel(item: any) {
    return Format.highLightText(item, this.search);
  }

  private getImagePath(img: string) {
    if (img === null || img === undefined || img === "") {
      return this.settings.Uploads + "/" + this.settings.Mainlogo;
    }
    return this.settings.Uploads + "/" + img;
  }

  private async onLookupSearch(search: string) {
    const results = await this.getLookupOptions(search);
    this.searchResults = results;
  }

  private async getLookupOptions(search: string): Promise<any[]> {
    const url = `${Settings.HostName}/api/public${this.fdata.floatingFilterComponentParams.fkController}/list`;
    let res = await new CrudService().httpPost(url, search);
    if (res == null || res.ok === false) {
      console.error(this.$t("Crash"));
      return [];
    }

    return res.rowData;
  }
  //#endregion LOOKUP

  //#region PICKLIST
  private getPickListOptions() {
    if (this.fdata === null) {
      return;
    }

    var metaOpts = this.fdata.floatingFilterComponentParams;

    var items: any[] = [];
    Object.keys(metaOpts).forEach(function (item) {
      if (
        item != "typeFilterButton" &&
        item != "suppressFilterButton" &&
        item !== "processed__"
      ) {
        items.push({
          value: metaOpts[item],
          label: item,
        });
      }
    });

    items.push({
      value: -1,
      label: this.fdata.headerName,
      selected: true,
    });
    return items;
  }
  //#endregion PICKLIST
}
