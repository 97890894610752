
import Vue from "vue";
import Component from "vue-class-component";
import { Format } from "../core/helpers/formatHelper";
import { ShopService } from "../core/services/shopService";
import PopularProducts from "../components/popularProducts/popularProducts.vue";
import store from "../core/store";

@Component({
  components: { PopularProducts },
})
export default class PassResetOkPage extends Vue {
  error: string = "";

  async mounted() {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 500);
    new ShopService().removeCart();
  }

  getImagePath(img: string) {
    if (img === null || img === undefined || img === "") {
      return store.state.settings.Uploads + "/" + store.state.settings.Mainlogo;
    }
    return store.state.settings.Uploads + "/" + img;
  }
}
