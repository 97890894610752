
import { Component, Vue, Prop } from "vue-property-decorator";
import Popup from "../../components/popup/popup.vue";
import BookingByPlaceCard from "../../components/bookingByPlaceCard/bookingByPlaceCard.vue";
import PopupConfirmByPlace from "../../components/popupConfirmByPlace/popupConfirmByPlace.vue";

@Component({
  components: {
    Popup,
    BookingByPlaceCard,
    PopupConfirmByPlace
  },
})
export default class MatchesBanner extends Vue {
  @Prop({ required: true, type: Array }) matches!: any[];

  private get matchesToShow(){
    return this.matches.slice(0,4);
  }

  matchSelected: any;
  showPopUpConfirm: boolean = false;

  async mounted() {
    this.checkScroll();
    window.addEventListener("resize", this.checkScroll);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.checkScroll);
  }

  async joinBooking(e: any, booking: any) {
    e.preventDefault();

    const url =
      booking.bookingTemplateId !== null &&
      booking.bookingTemplateId !== undefined
        ? "/booking-visual/" +
          booking.id +
          "/" +
          booking.bookingTemplateId +
          "?date=" +
          booking.date
        : "/bookings-byplace?bookingId=" + booking.id + "&date=" + booking.date;

    if (this.$store.state.loggedIn === false) {
      this.$store.commit("openLogin", { backUrl: url });
      return;
    }

    if (
      booking.bookingTemplateId !== null &&
      booking.bookingTemplateId !== undefined
    ) {
      return this.$router.push(url);
    }

    this.matchSelected = booking;
    this.showPopUpConfirm = true;
  }

  checkScroll() {
    this.$nextTick(() => {
      const container = this.$refs.matchesBannerContainer as HTMLElement | null;
      const list = this.$refs.matchesBannerList as HTMLElement | null;

      if (container && list) {
        const hasHorizontalScroll = list.scrollWidth > container.clientWidth;
        list.style.justifyContent = hasHorizontalScroll
          ? "flex-start"
          : "center";
      }
    });
  }
}
