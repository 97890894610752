
import Component from '@modules/vue-class-component';
import { Emit, Prop, Watch } from '@modules/vue-property-decorator';
import Vue from 'vue';

export interface RadioPillItem {
    text: string;
    value: any;
}

@Component({
    
})
export default class RadioPills extends Vue {
    @Prop({ required: true }) items!: RadioPillItem[];
    @Prop({}) value!: any|null; // Used for v-model binding
    private selectedValue: any | null = this.value;

    @Watch("value", { immediate: true })
    private onValueChange(newVal: any) {
        this.selectedValue = newVal;
    }

    mounted() {
        const differentValues = new Set(this.items?.map(i => i.value));
        if (differentValues.size != this.items?.length) {
            throw new Error("Multiple items with same value");
        }
    }

    @Emit('input')
    private onItemClick(item: RadioPillItem) {
        this.selectedValue = item.value;
        return this.selectedValue;
    }
}
