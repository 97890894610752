var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Popup',{attrs:{"showOkButton":false},on:{"closed":function($event){return _vm.$emit('close-popup')}}},[_c('div',{staticClass:"card-login-register",attrs:{"slot":"body","id":"card-l-r"},slot:"body"},[_c('div',{staticClass:"card-form card-form-login"},[_c('form',{staticClass:"rd-form rd-mailform",attrs:{"novalidate":"novalidate"}},[_c('div',{staticClass:"box-pricing-body"},[_c('h5',{staticClass:"box-pricing-title"},[_vm._v(" "+_vm._s(_vm.bookingSelected.bookingTypeName)+" ")]),_c('h3',{staticClass:"box-pricing-price"},[_vm._v(" "+_vm._s(_vm.bookingSelected.startHour)+" ")]),_c('div',{staticClass:"box-pricing-time"},[_vm._v(" "+_vm._s(_vm.bookingSelected.duration)+" ")]),_c('div',{staticClass:"box-pricing-time"},[_vm._v(_vm._s(_vm.bookingSelected.date))]),_c('div',{staticClass:"box-pricing-divider"},[_c('div',{staticClass:"divider"})]),_c('ul',{staticClass:"box-pricing-list"},[(
                _vm.bookingSelected.minLevel !== null &&
                  _vm.bookingSelected.maxLevel !== null
              )?_c('li',{staticClass:"active"},[_vm._v(" "+_vm._s(_vm.$t("Level"))+": "+_vm._s(_vm.bookingSelected.minLevel?.toFixed(2))+"-"+_vm._s(_vm.bookingSelected.maxLevel?.toFixed(2))+" ")]):_vm._e(),(
                _vm.bookingSelected.maxParticipants !== undefined &&
                  _vm.bookingSelected.maxParticipants !== null
              )?_c('li',{staticClass:"active"},[_vm._v(" "+_vm._s(_vm.$t("Occupancy"))+": "+_vm._s(_vm.bookingSelected.participantsIn)+"/"+_vm._s(_vm.bookingSelected.maxParticipants)+" ")]):_vm._e(),(_vm.$route.query.place !== undefined)?_c('li',{staticClass:"active"},[_vm._v(" "+_vm._s(_vm.$t("Place"))+": "+_vm._s(_vm.$route.query.place)+" ")]):_vm._e(),(_vm.bookingSelected.resources.join() !== '')?_c('li',{staticClass:"active"},[_vm._v(" "+_vm._s(_vm.bookingSelected.resources.join())+" ")]):_vm._e(),(_vm.bookingSelected.price !== 0)?_c('li',{staticClass:"active"},[_vm._v(" "+_vm._s(_vm.$t("Price"))+": "+_vm._s(_vm.getMatchPrice(_vm.bookingSelected.price, _vm.bookingSelected.netPrice))+" ")]):_vm._e()]),_c('br')]),_c('SubmitButton',{staticClass:"button button-block button-secondary button-pipaluk",attrs:{"id":"addToCart","label":_vm.$t('Add to Cart')},on:{"click":function($event){return _vm.addToCart($event)}}}),(_vm.errorMsg != null && _vm.errorMsg != '')?_c('div',{staticClass:"warning",domProps:{"innerHTML":_vm._s(_vm.errorMsg)}}):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }