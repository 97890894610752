
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";

@Component({})
export default class LookupResults extends Vue {
  @Prop() search!: string;
  @Prop() searchResults!: any[];

  @Emit("selected")
  private selectResult(result: any) {
    return result;
  }
}
