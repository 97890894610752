
import { Component, Vue } from "@shared/node_modules/vue-property-decorator";
import CzMenu from "../menu/czmenu.vue";
import Footer from "@shared/components/footer/footer.vue";
import store from "@shared/core/store";

@Component({
  components: {
    CzMenu,
    Footer,
  },
})
export default class InSecure extends Vue {
  created() {}

  async mounted() {
    const shared = store.state.settings;
    if (shared.ButtonsColor !== null && shared.ButtonsColor !== undefined) {
      document.documentElement.style.setProperty(
        "--main-color",
        shared.ButtonsColor
      );

      document.documentElement.style.setProperty(
        "--main-color-rgb",
        this.convertHexToRgb(shared.ButtonsColor)
      );
    }
    if (shared.FontFamily !== null && shared.FontFamily !== undefined) {
      document.documentElement.style.setProperty(
        "--main-family",
        shared.FontFamily
      );
    }

    if (shared.FooterColor !== null && shared.FooterColor !== undefined) {
      document.documentElement.style.setProperty(
        "--main-footercolor",
        shared.FooterColor
      );
    }

    const logo = shared.Uploads + "/" + shared.Mainlogo;
    var link: any = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }
    link.href = logo;
  }

  convertHexToRgb(hexadecimal) {
    let red,
      green,
      blue = 0;
    if (hexadecimal.includes("rgb")) {
      hexadecimal = hexadecimal
        .replace("rgb", "")
        .replace("(", "")
        .replace(")", "");
      let color = hexadecimal.split(",");
      red = color[0];
      green = color[1];
      blue = color[2];
    } else {
      let hexColor = hexadecimal.replace("#", "");
      red = parseInt(hexColor.substring(0, 2), 16);
      green = parseInt(hexColor.substring(2, 4), 16);
      blue = parseInt(hexColor.substring(4, 6), 16);
    }
    return `${red}, ${green}, ${blue}`;
  }
}
