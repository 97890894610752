import { render, staticRenderFns } from "./activitiesBanner.vue?vue&type=template&id=6eca290d&scoped=true&"
import script from "./activitiesBanner.vue?vue&type=script&lang=ts&"
export * from "./activitiesBanner.vue?vue&type=script&lang=ts&"
import style0 from "./activitiesBanner.vue?vue&type=style&index=0&id=6eca290d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../padel/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eca290d",
  null
  
)

export default component.exports