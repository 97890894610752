
import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {},
})
export default class RazorpayRedirectPayment extends Vue {
  data: any = null;
  errorMsg: string = "";

  async mounted() {
    await this.loadData();
  }

  count: number = 10;
  async loadData() {
    let orderId = this.$route.query.order_id;
    let paymentId = this.$route.query.payment_id;
    let signature = this.$route.query.signature;

    let url = `${Settings.HostName}/api/razorpay/status/${orderId}/${paymentId}/${signature}`;
    let data = await new CrudService().httpGet(url);
    if (data == null) {
      this.$router.push("/cz/ko");
      return;
    }

    if (data.retry === true && this.count > 0) {
      this.count--;
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return this.loadData();
    }

    if (data.ok === true) {
      this.$router.push("/cz/ok");
      return;
    }
    this.$router.push("/cz/ko");
  }
}
