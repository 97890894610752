
import Component from '@modules/vue-class-component';
import { Emit, Prop } from '@modules/vue-property-decorator';
import RangeBar from '@shared/components/bars/rangeBar.vue';
import RadioPills, { RadioPillItem } from '@shared/components/form/radioPills.vue';
import Popup from '@shared/components/popup/popup.vue';
import { BookingGender } from '@shared/core/constants';
import { Settings } from '@shared/core/services/configService';
import { CrudService } from '@shared/core/services/crudService';
import { CustomerHelper } from '@shared/crm/helpers/customerHelper';
import Vue from 'vue';

@Component({
    components: {
        Popup,
        RadioPills,
        RangeBar,
    },
})
export default class CreateMatchPopup extends Vue {
    @Prop({ required: true }) resourceId!: number;

    private readonly MatchTypes: RadioPillItem[] = [
        { text: this.$t('Competitive'), value: true },
        { text: this.$t('Friendly'), value: false },
    ]
    private errorMsg: string|null = null;
    private get Genders(): RadioPillItem[] {
        const genders = [
            { text: this.$t('Mixed'), value: BookingGender.Mixed },
            { text: this.$t('Open'), value: BookingGender.Open },
        ];
        if (this.customerGender != null) {
            genders.unshift({ text: this.$t(BookingGender[this.customerGender]), value: this.customerGender });
        } else {
            genders.unshift(
                { text: this.$t('Male'), value: BookingGender.Male },
                { text: this.$t('Female'), value: BookingGender.Female },
            );
        }
        return genders;
    }

    private customerGender: BookingGender | null = null;
    private selectedGender: BookingGender = BookingGender.Open;
    private competitive: boolean = true;
    private levelRange: {
        levelProviderRange: [number, number],
        matchRange: [number, number],
    } = {
            levelProviderRange: [0, 7],
            matchRange: [0, 7],
        };
    private loadingData: boolean = false;

    async mounted() {
        await this.loadData();
    }

    private async loadData() {
        this.loadingData = true;
        let cid = CustomerHelper.getStoredCustomer();
        if (cid === undefined) {
            return;
        }
        await Promise.all([
            this.loadCustomerData(cid),
            this.loadLevelData(),
        ]);
        this.loadingData = false;
    }

    private async loadCustomerData(cid: number) {
        const url = `${Settings.HostName}/api/publicacademycustomers/${cid}`;
        const customer = await CustomerHelper.getCustomerData(cid, url);
        if (!customer) {
            return;
        }
        switch (customer.gender) {
            case BookingGender.Female:
            case BookingGender.Male:
                this.customerGender = customer.gender;
                this.selectedGender = customer.gender;
                break;
            default:
                this.selectedGender = BookingGender.Open
                break;
        }
    }

    private async loadLevelData() {
        const url = `${Settings.HostName}/api/publicbookings/byplace/${this.resourceId}/level-range`;
        const data = await new CrudService().httpGet(url);
        if (data == null || data === undefined || data.ok === false) {
            console.error("Crash getting level data");
            this.errorMsg = this.$t('Crash');
            return;
        }
        if (data.model.referenceCustomerLevel === null) {
            this.errorMsg = `${this.$t('Sorry, you do not have a level to create a match yet')}.<br/>`
                + `${this.$t('Use the Taykus app to find out your level or ask for information at the club')}.`;
            return;
        }
        this.levelRange = {
            levelProviderRange: [data.model.levelProviderRange.item1, data.model.levelProviderRange.item2],
            matchRange: [data.model.matchRange.item1, data.model.matchRange.item2],
        };
    }

    @Emit('ok')
    private onOkButtonClick(e: Event) {
        e.preventDefault();
        return {
            gender: this.selectedGender,
            competitive: this.competitive,
            matchRange: this.levelRange.matchRange
        };
    }

    @Emit("closed")
    private closePopup() { }
}
