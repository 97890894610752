
import { Component, Vue, Prop } from "vue-property-decorator";
import store from "../../core/store";

@Component
export default class HomeImage extends Vue {
  @Prop() data!: any;

  getImageUrl(name: string) {
    return store.state.settings.Uploads + "/" + name;
  }
}
