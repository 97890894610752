
import { Component, Vue, Prop } from "vue-property-decorator";
import { Settings } from "../../../core/services/configService";
import { CrudService } from "../../../core/services/crudService";
import store from "../../../core/store";
import { DateHelper } from "../../../core/helpers/dateHelper";

@Component
export default class ActivitiesBanner extends Vue {
  activities: any[] = [];

  async mounted() {
    let url = `${Settings.HostName}/api/publicactivities/list`;
    let data = await new CrudService().httpGet(url);
    if (data == null || !data.ok) {
      console.error("Crash");
      return;
    }
    this.activities = data.model;
    this.checkScroll();
    window.addEventListener("resize", this.checkScroll);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.checkScroll);
  }

  formatDate(date: string) {
    return DateHelper.toCultureString(date);
  }

  getImageActivity(name: string) {
    if (name !== undefined && name !== "" && name !== null) {
      return store.state.settings.Uploads + "/" + name;
    }
    return "/img/activity-default.png";
  }
  buildUrlActivity(activity: any){
    return `/activity/${activity.id}`;
  }
  checkScroll() {
    this.$nextTick(() => {
      const container = this.$refs.activitiesBannerContainer as HTMLElement | null;
      const list = this.$refs.activitiesBannerList as HTMLElement | null;

      if (container && list) {
        const hasHorizontalScroll = list.scrollWidth > container.clientWidth;
        list.style.justifyContent = hasHorizontalScroll ? "flex-start" : "center";
      }
    });
  }
}
