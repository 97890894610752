
import { CrudService } from "../core/services/crudService";
import { Settings, TenantSettings } from "../core/services/configService";
import { Format } from "../core/helpers/formatHelper";
import Vue from "vue";
import { DateHelper } from "../core/helpers/dateHelper";
import store from "../core/store";
import Component from "vue-class-component";
import { LocalStorage } from "../core/localStorage";
import CancelReturnsInfo from "../components/cancelReturnsInfoCz/cancelReturnsInfoCz.vue";
import { ShopService } from "../core/services/shopService";
import Popup from "../components/popup/popup.vue";

@Component({
  components: {
    CancelReturnsInfo,
    Popup,
  },
} as any)
export default class MyFeesPage extends Vue {
  data: any = null;
  error: string = "";
  infoReturns: any = null;
  reload: number = 1;
  settings: TenantSettings = {};
  debts: any = null;
  salesToPay: any = [];
  selectedCpSale: any = null;

  async mounted() {
    this.settings = store.state.settings;
    let cid = 0;
    try {
      cid = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
    } catch {
      console.error("not customer logged found");
    }

    await this.loadData(cid);
  }

  async loadData(customerId: number) {
    let url = `${Settings.HostName}/api/publiccustomerperiodicsales/customer-fees/${customerId}`;
    let data = await new CrudService().httpGet(url);
    if (data === null || data.ok === false) {
      console.error("Crash getting Your Fees");
      this.error = this.$t("Crash").toString();
      return;
    }

    this.data = data.model;
  }

  selectDebt(debt: any) {
    const found = this.salesToPay.find((y) => y.saleNumber === debt.saleNumber);
    if (found === undefined) {
      this.salesToPay.push(debt);
      return;
    }

    this.salesToPay = this.salesToPay.filter(
      (item) => item.saleNumber !== found.saleNumber
    );
  }

  addToCart(e: any) {
    e.preventDefault();
    var shopService = new ShopService();
    for (const sale of this.salesToPay) {
      shopService.addSaleToCart(sale, e.currentTarget);
    }
  }

  formatPrice(price: string) {
    return Format.formatCurrency(price);
  }

  getDateFormatted(dt: string) {
    return DateHelper.formatDate(new Date(dt)).split(" ")[0];
  }

  showAddSale() {
    if (Format.IsNull(this.data) || this.data.length === 0) {
      return false;
    }

    return this.data.map((a) => a.fees.length).reduce((a, b) => a + b) > 0;
  }

  async stop() {
    this.error = "";
    let cid = 0;
    try {
      cid = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
    } catch {
      console.error("not customer logged found");
    }
    if (this.selectedCpSale == null) {
      return;
    }

    const url = `${Settings.HostName}/api/publiccustomerperiodicsales/stop-periodicsale/${cid}/${this.selectedCpSale.nextNotPaidFeeInfo.cpsId}`;
    let data = await new CrudService().httpPost(url);
    if (data == null) {
      console.error("Crash cancelling my bookings");
      return;
    }

    if (data.errorMsg !== null && data.errorMsg !== undefined) {
      this.error = data.errorMsg;
      return;
    }

    this.reload += 1;

    return await this.loadData(cid);
  }
}
