import { PaymentMethodType } from '../constants';

export class SharedPaymentsService {    
    public isAllowed(paymentMethodType: PaymentMethodType) : boolean {        
        switch (paymentMethodType) {
            case PaymentMethodType.Redsys:
            case PaymentMethodType.Stripe:
                return true;
            default: 
                return false;
        }
    }
}