
import {
  Component,
  Vue,
} from "../../../shared/node_modules/vue-property-decorator";
import Slider from "../../../shared/components/slider/slider.vue";
import HomeImage from "../../../shared/components/homeImage/homeImage.vue";
import TrendingProducts from "../../../shared/components/trendingProducts/trendingProducts.vue";
import Gallery from "../../../shared/components/gallery/gallery.vue";
import UsInNumbers from "../../../shared/components/usInNumbers/usInNumbers.vue";
import Testimonial from "../../../shared/components/testimonial/testimonial.vue";
import Team from "../../../shared/components/team/team.vue";
import LastPosts from "../../../shared/components/lastPosts/lastPosts.vue";
import DirectAccessMenu from "../../../shared/components/directAccessMenu/directAccessMenu.vue";
import PopularProducts from "../../../shared/components/popularProducts/popularProducts.vue";
import Sponsors from "../../../shared/components/sponsors/sponsors.vue";
import Banners from "../../../shared/components/banners/banners.vue";
import BookingTypesBanner from "../../../shared/components/banners/bookingTypesBanner.vue";
import SchoolBanner from "../../../shared/components/banners/schoolBanner.vue";
import MatchesBanner from "../../../shared/components/banners/matchesBanner.vue";
import BookingEventsBanner from "../../../shared/components/banners/bookingEventsBanner.vue";
import CtaFullWidth from "../../../shared/components/cta/cta.vue";
import ActivitiesBanner from "../../../shared/crm/components/activities/activitiesBanner.vue";
import MembershipsBanner from "../../../shared/sales/components/memberships/membershipsBanner.vue";
import { Settings, TenantSettings } from "../../../shared/core/services/configService";
import { CrudService } from "../../../shared/core/services/crudService";
import store from "../../../shared/core/store";
import { BookingConcept } from "../../../shared/crm/constants";
import { LocalStorage } from "@shared/core/localStorage";

@Component({
  components: {
    Slider,
    DirectAccessMenu,
    TrendingProducts,
    Gallery,
    UsInNumbers,
    Testimonial,
    Team,
    LastPosts,
    PopularProducts,
    Sponsors,
    Banners,
    ActivitiesBanner,
    MembershipsBanner,
    CtaFullWidth,
    HomeImage,
    BookingTypesBanner,
    MatchesBanner,
    SchoolBanner,
    BookingEventsBanner
  },
})
export default class IndexPage extends Vue {
  settings: TenantSettings = {};
  directAccesMenuData: any[] = [];
  czTemplateSettings: any;
  // trendingProductImg: string = "";
  gallery: any[] = [];
  galleryData: any[] = [];
  galleryPath: string = "";
  usInNumbers: any = null;
  slider: any = null;
  bookingTypes: any[] = [];
  bookingTypesBooking: any[] = [];
  bookingTypesSchool: any[] = [];
  windowWidth: number = window.innerWidth;
  concept: BookingConcept = BookingConcept.Booking;
  matches: any[] = [];
  bookingEvents: any[] = [];

  private get isMobile() {
    return this.windowWidth < 768;
  }

  async mounted() {
    this.settings = store.state.settings;
    //this.configureDirectAccess();

    const url = `${Settings.HostName}/api/czconfigs/template`;
    const data = await new CrudService().httpGet(url);
    if (data == null || data.data == null || !data.ok) {
      console.error("Crash getting cz template settings");
      return;
    }

    this.slider = data.data;
    await this.loadGallery();
    await this.getBookingTypes();
    await this.getBookingsByPlace();
    window.addEventListener("resize", this.handleResize);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  }

  async getBookingTypes() {
    const url = `${Settings.HostName}/api/publicbookings/booking-types-list`;
    const data = await new CrudService().httpGet(url);
    if (data == null || data.model == null || !data.ok) {
      console.error("Crash getting index booking types");
      return;
    }
    this.bookingTypes = data.model;
    this.bookingTypesBooking = data.model.filter((x) => x.concept === BookingConcept.Booking);
    this.bookingTypesSchool = data.model.filter((y) => y.concept === BookingConcept.School);
  }

  async getBookingsByPlace() {
    let cid = 0;
    let sid = LocalStorage.getWithTimeout("cc");
    if (sid !== undefined && sid !== null) {
      cid = JSON.parse(sid).id;
    }

    let url = `${Settings.HostName}/api/publicbookings/byplace-list`;
    let payLoad: any = {
      customerId: cid === 0 ? null : cid,
      date: "",
    };

    const res = await new CrudService().httpPost(url, payLoad);
    this.fillBookingsByConcept(res);
  }
  fillBookingsByConcept(res: any){
    const matches: any[] = [];
    const events: any[] = [];
    
    for (const item of res.data) {
      if (item.concept === BookingConcept.Match) {
        matches.push(item);
      }

      if(item.concept === BookingConcept.Event){
        events.push(item);
      }
    }

    this.matches = matches;
    this.bookingEvents = events;
  }
  async loadGallery() {
    const dbGallery = localStorage.getItem(Settings.Prefix + "all-gallery"); // setted in czmenu todo: if no menu wont load
    if (dbGallery !== null) {
      const gallery = JSON.parse(dbGallery);
      this.galleryData = gallery;
      return;
    }
  }

  handleResize() {
    this.windowWidth = window.innerWidth; // Actualiza el ancho de la ventana
  }

  cofigureUsInNumbers(data: any) {
    if (
      data.data.number1 === null &&
      data.data.number2 === null &&
      data.data.number3 === null &&
      data.data.number4 === null
    ) {
      return;
    }

    this.usInNumbers = {
      number1: data.data.number1,
      number2: data.data.number2,
      number3: data.data.number3,
      number4: data.data.number4,
      textNumbers1: data.data.textNumbers1,
      textNumbers2: data.data.textNumbers2,
      textNumbers3: data.data.textNumbers3,
      textNumbers4: data.data.textNumbers4,
    };
  }

  configureDirectAccess() {
    // this.directAccesMenuData.push({
    //   //img: "/img/calendar.jpg",
    //   link: "/calendar",
    //   title: this.$t("Calendar"),
    // });
    // this.directAccesMenuData.push({
    //   // img: "/img/training.jpg",
    //   link: "/trainings",
    //   title: this.$t("Trainings"),
    // });
    // this.directAccesMenuData.push({
    //   //img: "/img/tournament.jpg",
    //   link: "/tournaments",
    //   title: this.$t("Tournaments"),
    // });
    this.directAccesMenuData.push({
      // img: "/img/account.jpg",
      link: "/account",
      title: this.$t("Account"),
    });
    this.directAccesMenuData.push({
      // img: "/img/notification.jpg",
      link: "#",
      title: this.$t("Notifications"),
    });
    this.directAccesMenuData.push({
      // img: "/img/finance.jpg",
      link: "#",
      title: this.$t("Your Orders"),
    });
  }
}
