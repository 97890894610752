
import { CrudService } from "../core/services/crudService";
import { Settings, TenantSettings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { LocalStorage } from "../core/localStorage";
import { Format } from "../core/helpers/formatHelper";
import InputText from "../components/form/inputText.vue";
import CreditCardForm from "../components/form/creditCardForm.vue";
import BookingSummary from "../components/bookingSummary/bookingSummary.vue";
import Popup from "../components/popup/popup.vue";
import Checkbox from "../components/form/checkBox.vue";
import TriggerAnchor from "../components/triggerAnchor/triggerAnchor.vue";
import { DateHelper } from "../core/helpers/dateHelper";
import LookUp from "../components/form/lookUp.vue";
import { BookingPaymentType, BookingTeam } from "../core/constants";
import { ShopService } from "../core/services/shopService";
import store from "@shared/core/store";
import { PriceDescriptor } from "../core/types/PriceDescriptor";

@Component({
  components: {
    InputText,
    Popup,
    Checkbox,
    CreditCardForm,
    TriggerAnchor,
    LookUp,
    BookingSummary,
  },
})
export default class CheckoutBookingPage extends Vue {
  settings: TenantSettings = {};
  error: string = "";
  data: any = null;
  transport: any = null;
  errors: any = {};
  paymentType: BookingPaymentType = BookingPaymentType.Full;
  booking: any = null;
  blocks: any = null;
  addPlayerPopup: boolean = false;
  players: any[] = [];
  owner: any = null;
  team1Selected: boolean = true;
  team2Selected: boolean = false;

  allowSharedPayment: boolean = false;
  allowFullPayment: boolean = true;
  allowPendingPayment: boolean = false;
  BookingPaymentType = BookingPaymentType;

  bookingPrice: number = 0;
  genericPrice: PriceDescriptor | null = null;
  isMatch: boolean = false;

  showTooltip: boolean = false;
  sharedPaymentWithRetention: boolean = true;

  async created() {
    let sid = LocalStorage.getWithTimeout("cc");
    if (Format.IsNull(sid)) {
      this.$router.push("bookingtypes");
      return;
    }

    this.owner = JSON.parse(sid);

    const query = this.$route.query.pl;
    if (Format.IsNull(query)) {
      this.$router.push("bookingtypes");
      return;
    }

    let strQuery = "";
    try {
      strQuery = atob(query.toString());
    } catch (error) {
      this.$router.push("bookingtypes");
      return;
    }

    try {
      const data = JSON.parse(strQuery);
      this.booking = data.booking;

      const price = this.booking.price as PriceDescriptor;
      this.bookingPrice = price.amount;

      this.booking.resources = data.booking.resourceName;
      this.blocks = data.blocks;
      this.players.push({
        name: this.owner.name,
        customerId: this.owner.id,
        logo: this.owner.logo,
        team: BookingTeam.NotSet,
        price: this.bookingPrice / this.booking.maxParticipants,
      });
      this.isMatch = data.isMatch;

      const url = `${Settings.HostName}/api/shop/payment-mode-allowed`;
      const paymentMode = await new CrudService().httpGet(url);

      this.paymentType = BookingPaymentType.Full;
      this.allowPendingPayment = paymentMode?.pendingPaymentAllowed === true;
      this.allowSharedPayment = paymentMode?.sharedPaymentAllowed === true && !this.isMatch;
      this.allowFullPayment = true;
      this.sharedPaymentWithRetention = paymentMode?.sharedPaymentWithRetention === true;

      if (
        this.booking.price.isSpecialPrice &&
        paymentMode?.fullPaymentAllowedWithSpecialPrices === false
      ) {
        this.allowFullPayment = false;
      }

      // If the booking is for only one participant, only full payment makes sense
      // This overrides the previous fullPaymentAllowedWithSpecialPrices condition,
      // because that condition only applies when there are multiple participants
      // that might have different prices each
      if (this.booking.maxParticipants === 1) {
        this.allowSharedPayment = false;
      }
      if (
        !this.allowFullPayment &&
        !this.allowSharedPayment &&
        !this.allowPendingPayment
      ) {
        this.$store.commit(
          "openGlobalError",
          this.$t("No payment options available") +
            "." +
            this.$t("Please contact the club")
        );
        return;
      }
      if (this.allowSharedPayment) {
        this.paymentType = BookingPaymentType.Shared;
      }
      if (this.allowSharedPayment) {
        this.genericPrice = await this.loadPrice(-1);
      }
    } catch (error) {
      this.$router.push("bookingtypes");
      return;
    }
  }

  mounted() {
    window.scrollTo(0, 0);
  }

  toggleTooltip() {
    this.showTooltip = !this.showTooltip;
  }

  tooltipMouseOut() {
    this.showTooltip = false;
  }

  tooltipMouseIn() {
    this.showTooltip = true;
  }

  getImagePath(img: string) {
    console.log("img", img);
    if (img === null || img === undefined || img === "") {
      console.log(
        "img2",
        store.state.settings.Uploads + "/" + store.state.settings.Mainlogo
      );

      return store.state.settings.Uploads + "/" + store.state.settings.Mainlogo;
    }
    return store.state.settings.Uploads + "/" + img;
  }

  formatPrice(price: string) {
    return Format.formatCurrency(price);
  }

  getTeam(team: BookingTeam) {
    return team === BookingTeam.Team1
      ? this.$t("Team") + " " + 1
      : this.$t("Team") + " " + 2;
  }

  get ownerPrice() : number {
    return this.players[0].price;
  }

  getOwnerStrPrice() : number {
    return Format.formatCurrency(this.ownerPrice);
  }

  getSharedStrPrice() {
    const players =
      (this.genericPrice?.amount || 0) *
      (this.booking.maxParticipants - 1);
    return Format.formatCurrency(players + this.ownerPrice);
  }

  getTotalLabel() {
    if (this.paymentType === BookingPaymentType.Shared) {
      return this.$t("Proceed to Payment");
    }

    return (
      this.$t("Add to Cart") +
      " " +
      Format.formatCurrency(this.bookingPrice.toString())
    );
  }

  async addPlayer(customer: any) {
    this.addPlayerPopup = false;
    if (this.players.length === this.booking.maxParticipants) {
      this.error = this.$t("Max players reached").toString();
      this.$store.commit("openGlobalError", this.error);
      return;
    }

    if (this.players.length === 0) {
      this.players.push({
        name: this.owner.name,
        customerId: this.owner.id,
        logo: this.owner.logo,
        team: BookingTeam.NotSet,
        price: this.bookingPrice / this.booking.maxParticipants,
      });
    }

    const found = this.players.find((y) => y.customerId === customer.id);
    if (Format.IsNull(found)) {
      this.players.push({
        name: customer.name,
        customerId: customer.id,
        logo: customer.logo,
        team: BookingTeam.NotSet, // this.team1Selected ? BookingTeam.Team1 : BookingTeam.Team2,
        price: (await this.loadPrice(customer.id)).amount,
      });
      return;
    }
    found.team = BookingTeam.NotSet; //this.team1Selected ? BookingTeam.Team1 : BookingTeam.Team2;
  }

  removePlayer(player) {
    if (player.customerId === this.owner.id) {
      return;
    }
    this.players = this.players.filter(
      (y) => y.customerId !== player.customerId
    );
  }

  async loadPrice(customerId: number): Promise<PriceDescriptor> {
    const url = `${Settings.HostName}/api/publicbookings/loadprice`;

    const res = await new CrudService().httpPost(url, {
      startDate: this.booking.date,
      bookingTypeId: this.booking.bookingTypeId,
      duration: this.booking.duration,
      customerId: customerId,
      resourceId: this.blocks.find((y) => y.noOnline === false).resourceId,
    });

    if (Format.IsNull(res)) {
      this.$store.commit("openGlobalError", this.$t("crash"));
      return { amount: 0, isSpecialPrice: false };
    }

    if (res.ok === false) {
      this.error = res.errorMsg;
      this.$store.commit("openGlobalError", this.error);
      return { amount: 0, isSpecialPrice: false };
    }

    if(this.pricesWithTaxes) {
      return {
        amount: res.price.price / this.booking.maxParticipants,
        isSpecialPrice: res.price.isSpecialPrice,
      };
    }
    
    return {
      amount: res.price.netPrice / this.booking.maxParticipants,
      isSpecialPrice: res.price.isSpecialPrice,
    };
  }

  get pricesWithTaxes() {
    return this.$store.state.settings.PriceWithTaxes;
  }


  async goToPay(e: any) {
    e.preventDefault();
    this.error = "";
    let url = this.isMatch
      ? `${Settings.HostName}/api/publicbookings/byplace-prebook`
      : `${Settings.HostName}/api/publicbookings/prebook`;

    this.booking.paymentType = this.paymentType;

    const res = await new CrudService().httpPost(url, {
      booking: this.booking,
      blocks: this.blocks,
      customerIsParticipant: true,
      isMultiple: false,
      customerIds: this.players?.map(p => p.customerId),
      players: this.players,
    });

    if (res === undefined || res === null) {
      console.error("Crash pre booking ");
      this.$store.commit("openGlobalError", this.error);
      return;
    }

    if (res.ok === false) {
      if (res.model !== undefined && res.model !== null) {
        this.error = Object.values(res.model).join(", ");
        this.$store.commit("openGlobalError", this.error);
        return;
      }
      this.error = res.errorMsg;
      this.$store.commit("openGlobalError", this.error);
      return;
    }

    if (res.model.serviceFree === true) {
      return this.$router.push("/customer-bookings");
    }

    let sales = res.model;
    var shop = new ShopService();
    for (const sale of sales) {
      sale.title = sale.description;
      sale.paymentType = this.paymentType;
      sale.paymentDeadline = sale.paymentDeadline;
      sale.cancelUrl = `publicbookings/cancel/${sale.bookingId}/${this.owner.id}`;
      shop.addSaleToCart(sale, null);
    }

    this.$router.push("/checkout");

    //location.href = "/checkout-simplified?saleIds=" + res.model.id;
  }
}
