
import Popup from "../../components/popup/popup.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import store from "../../core/store";
import { LocalStorage } from "@shared/core/localStorage";
import { Settings } from "../../core/services/configService";
import { CrudService } from "../../core/services/crudService";
import { Format } from "@shared/core/helpers/formatHelper";
import { ShopService } from "@shared/core/services/shopService";
import SubmitButton from "../submitButton/submitButton.vue";

@Component({
  components: {
    Popup,
    SubmitButton
  },
})
export default class PopupConfirmByPlace extends Vue {
  @Prop({ required: true, type: Object }) bookingSelected!: any;
  errorMsg: string = "";

  getImageType(logo: string) {
    if (logo !== undefined && logo !== "" && logo !== null) {
      return store.state.settings.Uploads + "/" + logo;
    }
  }
  
  async addToCart(e: any) {
    this.errorMsg = "";
    e.preventDefault();
    let cid = 0;
    let sid = LocalStorage.getWithTimeout("cc");
    if (sid !== undefined && sid !== null) {
      cid = JSON.parse(sid).id;
    }
    if (cid === 0 || cid === null) {
      this.errorMsg = this.$t("Please login or register").toString();
      return;
    }

    const bookingId = this.bookingSelected.id;
    if (bookingId === null || bookingId === undefined) {
      this.errorMsg = this.$t("crash").toString();
      return;
    }

    let url = `${
      Settings.HostName
    }/api/publicbookings/byplace-prebook-place/${cid}/${bookingId}/${Format.getOrigin()}`;
    if (this.$route.query.placeId !== undefined) {
      url += "/" + this.$route.query.placeId;
    }
    const res = await new CrudService().httpPost(url, {});

    if (res == null || res == undefined) {
      console.error("Crash getting bookings by place");
      this.errorMsg = this.$t("Crash").toString();
      return;
    }
    if (res.ok === false) {
      this.errorMsg = res.errorMsg;
      return;
    }

    if (res.model.serviceFree === true) {
      return this.$router.push("/customer-bookings");
    }

    let sale = res.model;
    sale.title = sale.description;
    sale.cancelUrl = `publicbookings/cancel/${bookingId}/${cid}`;

    new ShopService().addSaleToCart(sale, e.currentTarget);
    this.$router.push("/checkout");
  }

  getMatchPrice(price: string, netPrice: string) {
    return store.state.settings.PriceWithTaxes
      ? Format.formatCurrency(price)
      : Format.formatCurrency(netPrice);
  }
}
