export const CustomRegisterForm = "CustomRegisterForm";
export const ParentNameActivity = "Activity";

export enum CustomerStatus { Active = 0, Temp = 1 }

export enum LevelProvider { Taykus = 0, WPR = 1 }

export enum Sport { NoSport = 0, Soccer = 1, Padel = 2, Tennis = 3, Basket = 4, Golf = 5, Pickleball = 6, Padbol = 7, Racquetball = 8, Squash = 9 }

export enum BookingGender {
    Male = 0,
    Female = 1,
    Mixed = 2,
    Open = 3,
}

export enum BookingConcept {
    Booking = 0,
    Match = 1,
    School = 2,
    Event = 3,
}

