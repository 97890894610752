import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=c8c05c52&scoped=true&"
import script from "./footer.vue?vue&type=script&lang=ts&"
export * from "./footer.vue?vue&type=script&lang=ts&"
import style0 from "./footer.vue?vue&type=style&index=0&id=c8c05c52&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../padel/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8c05c52",
  null
  
)

export default component.exports