
import { Component, Vue, Prop } from "vue-property-decorator";
import { Settings } from "../../core/services/configService";
import { CrudService } from "../../core/services/crudService";
import store from "../../core/store";
import { Format } from "@shared/core/helpers/formatHelper";
import { LocalStorage } from "@shared/core/localStorage";
import { ShopService } from "@shared/core/services/shopService";
import Popup from "../../components/popup/popup.vue";
import PopupConfirmByPlace from "../../components/popupConfirmByPlace/popupConfirmByPlace.vue";
import BookingByPlaceCard from "../../components/bookingByPlaceCard/bookingByPlaceCard.vue";
import SubmitButton from "../submitButton/submitButton.vue";

@Component({
  components: {
    Popup,
    SubmitButton,
    PopupConfirmByPlace,
    BookingByPlaceCard
  },
})
export default class BookingEventsBanner extends Vue {
  @Prop({ required: true, type: Array }) bookings!: any[];

  private get bookingsToShow(){
    return this.bookings.slice(0,4);
  }

  bookingSelected: any;
  errorMsg: string = "";
  showPopUpConfirm: boolean = false;

  
  async mounted() {
    this.checkScroll();
    window.addEventListener("resize", this.checkScroll);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.checkScroll);
  }

  getImageType(logo: string) {
    if (logo !== undefined && logo !== "" && logo !== null) {
      return store.state.settings.Uploads + "/" + logo;
    }
  }

  async joinBooking(e: any, booking: any) {
    e.preventDefault();

    const url =
      booking.bookingTemplateId !== null &&
      booking.bookingTemplateId !== undefined
        ? "/booking-visual/" +
          booking.id +
          "/" +
          booking.bookingTemplateId +
          "?date=" +
          booking.date
        : "/bookings-byplace?bookingId=" + booking.id + "&date=" + booking.date;

    if (this.$store.state.loggedIn === false) {
      this.$store.commit("openLogin", { backUrl: url });
      return;
    }

    if (booking.bookingTemplateId != null) {
      return this.$router.push(url);
    }

    this.bookingSelected = booking;
    this.showPopUpConfirm = true;
  }
  
  async addToCart(e: any) {
    this.errorMsg = "";
    e.preventDefault();
    let cid = 0;
    let sid = LocalStorage.getWithTimeout("cc");
    if (sid !== undefined && sid !== null) {
      cid = JSON.parse(sid).id;
    }
    if (cid === 0 || cid === null) {
      this.errorMsg = this.$t("Please login or register").toString();
      return;
    }

    const bookingId = this.bookingSelected.id;
    if (bookingId === null || bookingId === undefined) {
      this.errorMsg = this.$t("crash").toString();
      return;
    }

    let url = `${
      Settings.HostName
    }/api/publicbookings/byplace-prebook-place/${cid}/${bookingId}/${Format.getOrigin()}`;
    if (this.$route.query.placeId !== undefined) {
      url += "/" + this.$route.query.placeId;
    }
    const res = await new CrudService().httpPost(url, {});

    if (res == null || res == undefined) {
      console.error("Crash getting bookings by place");
      this.errorMsg = this.$t("Crash").toString();
      return;
    }
    if (res.ok === false) {
      this.errorMsg = res.errorMsg;
      return;
    }

    if (res.model.serviceFree === true) {
      return this.$router.push("/customer-bookings");
    }

    let sale = res.model;
    sale.title = sale.description;
    sale.cancelUrl = `publicbookings/cancel/${bookingId}/${cid}`;

    new ShopService().addSaleToCart(sale, e.currentTarget);
    this.$router.push("/checkout");
  }

  checkScroll() {
    this.$nextTick(() => {
      const container = this.$refs.bookingsBannerContainer as HTMLElement | null;
      const list = this.$refs.bookingsBannerList as HTMLElement | null;

      if (container && list) {
        const hasHorizontalScroll = list.scrollWidth > container.clientWidth;
        list.style.justifyContent = hasHorizontalScroll
          ? "flex-start"
          : "center";
      }
    });
  }

  getMatchPrice(price: string, netPrice: string) {
    return store.state.settings.PriceWithTaxes
      ? Format.formatCurrency(price)
      : Format.formatCurrency(netPrice);
  }
}
