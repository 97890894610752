
import { Component, Vue } from "vue-property-decorator";
import { Settings } from "../../../core/services/configService";
import { CrudService } from "../../../core/services/crudService";
import store from "../../../core/store";
import { ShopService } from "../../../core/services/shopService";
import { Format } from "@shared/core/helpers/formatHelper";

@Component
export default class MembershipsBanner extends Vue {
  memberships: any[] = [];

  async mounted() {
    let url = `${Settings.HostName}/api/publicmemberships/list`;
    let data = await new CrudService().httpGet(url);
    if (data == null || !data.ok) {
      console.error("Crash");
      return;
    }
    this.memberships = data.model;
    this.checkScroll();
    window.addEventListener("resize", this.checkScroll);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.checkScroll);
  }

  buildUrlShop(product){
    return `/shop/${product.id}`;
  }

  getImageProduct(name: string) {
    if (name !== undefined && name !== "" && name !== null) {
      return store.state.settings.Uploads + "/" + name;
    }
    return store.state.settings.Uploads + "/" + store.state.settings.Mainlogo;
  }

  formatCurrency(pr: any) {
    if (pr.netPrice == null) {
      return this.$t("Precio no disponible");
    }
    var p = store.state.settings.PriceWithTaxes ? pr.grossPrice : pr.netPrice;
    return Format.formatCurrency(p);
  }

  addToCart(p: any, e: any) {
    e.preventDefault();
    new ShopService().addProductToCart(p, 1, e.currentTarget);
  }

  checkScroll() {
    this.$nextTick(() => {
      const container = this.$refs
        .membershipsBannerContainer as HTMLElement | null;
      const list = this.$refs.membershipsBannerList as HTMLElement | null;

      if (container && list) {
        const hasHorizontalScroll = list.scrollWidth > container.clientWidth;
        list.style.justifyContent = hasHorizontalScroll
          ? "flex-start"
          : "center";
      }
    });
  }
}
