
import { Component, Prop, Vue } from "vue-property-decorator";
import store from "../../core/store";
import { Format } from "@shared/core/helpers/formatHelper";

@Component
export default class BookingTypesBanner extends Vue {
  @Prop() bookingTypes: any;

  private get bookingTypesToShow(){
    return this.bookingTypes.slice(0,4);
  }

  async mounted() {
    this.checkScroll();
    window.addEventListener("resize", this.checkScroll);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.checkScroll);
  }

  buildUrlBooking(bt: any) {
    return `/bookings/${bt.id}`;
  }

  getImageType(name: string) {
    if (name !== undefined && name !== "" && name !== null) {
      return store.state.settings.Uploads + "/" + name;
    }
    return store.state.settings.Uploads + "/" + store.state.settings.Mainlogo;
  }

  getPrice(pr: any) {
    return this.formatText(
      store.state.settings.PriceWithTaxes ? pr.grossPrice : pr.netPrice
    );
  }

  formatText(amount: string | null) {
    if (amount == null) {
      return "   ";
    }
    return this.$t("From") + " " + Format.formatCurrency(amount as string);
  }

  checkScroll() {
    this.$nextTick(() => {
      const container = this.$refs
        .bookingTypesBannerContainer as HTMLElement | null;
      const list = this.$refs.bookingTypesBannerList as HTMLElement | null;

      if (container && list) {
        const hasHorizontalScroll = list.scrollWidth > container.clientWidth;
        list.style.justifyContent = hasHorizontalScroll
          ? "flex-start"
          : "center";
      }
    });
  }
}
