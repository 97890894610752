
import { CrudService } from "../core/services/crudService";
import { Settings, TenantSettings } from "../core/services/configService";
import Vue from "../node_modules/vue";
import Component from "../node_modules/vue-class-component";
import { LocalStorage } from "../core/localStorage";
import { DateHelper } from "../core/helpers/dateHelper";
import { Format } from "../core/helpers/formatHelper";
import Popup from "../components/popup/popup.vue";
import DatePicker from "../components/datePicker/datePicker.vue";
import { DatePickerMode, IsMobile } from "../core/constants";
import { Global } from "../core/helpers/global";
import SubmitButton from "../components/submitButton/submitButton.vue";
import store from "../core/store";
import { BookingConcept } from "@shared/crm/constants";
import BookingByPlaceCard from "../components/bookingByPlaceCard/bookingByPlaceCard.vue";
import PopupConfirmByPlace from "../components/popupConfirmByPlace/popupConfirmByPlace.vue";

@Component({
  components: {
    Popup,
    DatePicker,
    SubmitButton,
    BookingByPlaceCard,
    PopupConfirmByPlace
  },
})
export default class BookingsByPlacePage extends Vue {
  settings: TenantSettings = {};
  anyByType: boolean = false;
  bookAllowed: boolean = false;
  errorMsg: string = "";
  date: string | undefined = "";
  bookingType: { id: number; allowCreateBookByPlace: boolean } | null = null;
  data: any[] = [];
  dataByDays: any[] = [];
  bookingTypes: any[] = [];
  bookingTypesByPlace: any[] = [];
  showPopUpConfirm: boolean = false;
  bookingSelected: any = {};
  loaded: boolean = false;

  created() {
    if (this.$route.query.date !== undefined) {
      this.date = this.$route.query.date?.toString();
    }
  }

  async mounted() {
    this.settings = store.state.settings;
    let url = `${Settings.HostName}/api/publicbookings/filters`;
    let res = await new CrudService().httpGet(url);
    if (res == null || res.ok === false) {
      console.error("Crash getting filters");
      this.errorMsg = this.$t("Crash").toString();
      return;
    }

    this.bookingTypes = res.model.bookingTypes;
    if (
      this.bookingTypes === null ||
      this.bookingTypes === undefined ||
      this.bookingTypes.length === 0
    ) {
      this.errorMsg = this.$t("No bookings by place available").toString( );
      return;
    }

    this.bookingTypesByPlace = this.bookingTypes.filter(
      (y) => y.byPlace === true
    );
    if (this.bookingTypes.length === 1) {
      this.bookingType = this.bookingTypes[0];
      this.bookingTypes = [];
    }
    if (this.$route.params.bookingTypeId !== undefined) {
      this.bookingType = this.bookingTypes.find(
        (bt) => bt.id === Number(this.$route.params.bookingTypeId)
      );
    }

    if (this.$route.query.matches) {
      this.bookingTypesByPlace = this.bookingTypesByPlace.filter((y) => y.concept === BookingConcept.Match);
    }

    if (this.$route.query.school) {
      this.bookingTypesByPlace = this.bookingTypesByPlace.filter((y) => y.concept === BookingConcept.School);
    }

    if (this.$route.query.events) {
      this.bookingTypesByPlace = this.bookingTypesByPlace.filter((y) => y.concept === BookingConcept.Event);
    }


    if (this.bookingTypesByPlace.length === 1 && this.bookingType == null) {
      this.bookingType = this.bookingTypesByPlace[0];
      this.bookingTypes = [];
      this.bookingTypesByPlace = [];
    }

    await this.loadData();
    this.loaded = true;
    if (this.$route.query.bookingId !== undefined) {
      this.bookingSelected = this.data.find(
        (y) => y.id == Number(this.$route.query.bookingId)
      );

      if (this.bookingSelected === undefined) {
        console.error(
          "wrong url booking id param" + this.$route.query.bookingId
        );
        this.errorMsg = this.$t("crash").toString();
        return;
      }
      this.showPopUpConfirm = true;
    }
    this.checkScroll();
    window.addEventListener("resize", this.checkScroll);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.checkScroll);
  }

  async loadData() {
    this.errorMsg = "";
    let cid = 0;
    let sid = LocalStorage.getWithTimeout("cc");
    if (sid !== undefined && sid !== null) {
      cid = JSON.parse(sid).id;
    }

    this.data = [];
    this.dataByDays = [];

    const url = `${Settings.HostName}/api/publicbookings/byplace-list`;

    Global.TriggerDomId = "loader";
    let payLoad: any = {
      bookingTypeId: this.bookingType !== null ? this.bookingType.id : null,
      customerId: cid === 0 ? null : cid,
    };

    if (!Format.IsNull(this.data)) {
      payLoad.date = this.date;
    }

    const res = await new CrudService().httpPost(url, payLoad);
    if (res == null || res == undefined) {
      console.error("Crash getting bookings by place");
      return;
    }

    if (res.ok === false) {
      this.errorMsg = res.errorMsg;
      return;
    }

    if (res.data === null || res.data === undefined || res.data.length === 0) {
      this.errorMsg =
        this.$t("Oops! there is nothing you can join").toString() +
        ". " +
        this.$t("Try changing the date").toString();

      return;
    }

    console.log(this.$route.query.events);
    if (this.$route.query.matches && this.bookingType == null) {
      this.data = res.data.filter((y) => y.concept === BookingConcept.Match);
    } else if (this.$route.query.events && this.bookingType == null) {
      this.data = res.data.filter((y) => y.concept === BookingConcept.Event);
    }else if (this.$route.query.school && this.bookingType == null) {
      this.data = res.data.filter((y) => y.concept === BookingConcept.School);
    }else {
      this.data = res.data;
    }
    this.setUpData();
  }

  setUpData() {
    this.dataByDays = [];

    const daysOfWeek = [
      { title: this.$t("Mondays"), index: 1 },
      { title: this.$t("Tuesdays"), index: 2 },
      { title: this.$t("Wenesdays"), index: 3 },
      { title: this.$t("Thursdays"), index: 4 },
      { title: this.$t("Fridays"), index: 5 },
      { title: this.$t("Saturdays"), index: 6 },
      { title: this.$t("Sundays"), index: 0 },
    ];

    daysOfWeek.forEach(day => {
      const filteredData = this.data.filter(y =>
        DateHelper.isInDayOfWeek(DateHelper.toSystemDate(y.start), day.index)
      );

      if (filteredData.length > 0) {
        this.dataByDays.push({
          title: day.title,
          data: filteredData
        });
      }
    });
  }

  book(e: any, booking: any) {
    e.preventDefault();

    this.errorMsg = "";
    if (booking === undefined) {
      console.error("not booking found " + booking.id);
    }

    const url =
      booking.bookingTemplateId !== null &&
      booking.bookingTemplateId !== undefined
        ? "/booking-visual/" +
          booking.id +
          "/" +
          booking.bookingTemplateId +
          "?date=" +
          this.date
        : "/bookings-byplace?bookingId=" + booking.id + "&date=" + this.date;

    if (this.$store.state.loggedIn === false) {
      this.$store.commit("openLogin", { backUrl: url });
      return;
    }

    if (
      booking.bookingTemplateId !== null &&
      booking.bookingTemplateId !== undefined
    ) {
      return this.$router.push(url);
    }

    this.bookingSelected = booking;
    this.showPopUpConfirm = true;
  }

  getOrigin() {
    return Format.getOrigin();
  }

  bookingTypeSelected(bType: any) {
    if (
      bType.byPlace === true &&
      (Format.IsNull(bType.allowCreateBookByPlace) ||
        bType.allowCreateBookByPlace == false)
    ) {
      this.filterByBookingType(bType.id, null);
      return;
    }
    return this.$router.push("/bookings/" + bType.id);
  }

  filterByBookingType(
    bookingType: { id: number; allowCreateBookByPlace: boolean } | null,
    e: any
  ) {
    if (e !== null) {
      e.preventDefault();
    }
    this.bookingType = bookingType;
    this.loadData();
  }

  filterByDate(date: any) {
    if (this.loaded === false) {
      return;
    }
    this.date = date.systemStrDate;
    this.loadData();
  }
  datePickerMode() {
    return DatePickerMode.Date;
  }

  isMobile() {
    return IsMobile;
  }

  checkScroll() {
    this.$nextTick(() => {
        const containers = this.$refs.matchesBannerContainer as HTMLElement[];
        const lists = this.$refs.matchesBannerList as HTMLElement[];
        const containerSections = this.$refs.containerSection as HTMLElement[];

        if (containers && lists && containers.length === lists.length) {
          containers.forEach((container, index) => {
            const list = lists[index];
            const containerSection = containerSections[index];
            if (list && container) {
              const hasHorizontalScroll = list.scrollWidth > container.clientWidth;
              if (hasHorizontalScroll) {
                list.style.justifyContent = "flex-start";
                if (containerSection && window.innerWidth > 1440) {
                  containerSection.style.paddingRight = "7.5%";
                }
              }else{
                list.style.justifyContent = "center";
              }
            }
          });
        }
      });
  }
}
