import { AppCommType } from "../constants";

export class AppsHelper {

    static isSafari() {
        return /^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
        );
    }
    static isAndroidWebView() {
        return /Android/.test(navigator.userAgent) && /Version\/[\d.]+/.test(navigator.userAgent);
    }

    static isIOSWebView() {
        const webview = localStorage.getItem("webview");
        if (webview) {
            return true;
        }
        return false;
    }

    static isSafariOrIosWebView() {
        return AppsHelper.isSafari() || AppsHelper.isIOSWebView();
    }

    static sendToIOS(window: any, jsonData: any) {
        if (this.isIOSWebView()) {
            const fakewindow: any = window;
            if ("webkit" in window && "messageHandlers" in fakewindow.webkit) {
                fakewindow.webkit.messageHandlers.jsHandler.postMessage(JSON.stringify(jsonData), '*');//todo: para produccion deberiamos poner el origen 'https://taykus.com' en vez de * para segurar de que es nuestro dominio
                return true;
            } else {
                console.log("webkit.messageHandlers not supported in this browser.");
            }
        }
    }

    static isWebView() {
        return AppsHelper.isAndroidWebView() || AppsHelper.isIOSWebView();
    }

    static sendMessage(message: any, type: AppCommType) {
        if (!AppsHelper.isWebView()) {
            return;
        }
        console.log("locations sendmessage");
        console.log(message);
        if (AppsHelper.isIOSWebView()) {
            return AppsHelper.sendToIOS(window, message);
        }

        const w = window as any;
        switch (type) {
            case AppCommType.Location: w.Android.onLocationClick(message);
                return;
        }
    }
}