
import Vue from "vue";
import Component from "vue-class-component";
import { ShopService } from "../core/services/shopService";
import PopularProducts from "../components/popularProducts/popularProducts.vue";
import BookingSummary from "../components/bookingSummary/bookingSummary.vue";

@Component({
  components: { PopularProducts, BookingSummary },
})
export default class PaymentOkPage extends Vue {
  booking: any = {};
  bookingUrl: string = "";
  copied: boolean = false;

  async mounted() {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 500);

    new ShopService().removeCart();
    window.parent.postMessage("payment_ok", "*");
    // this.booking = {
    //   duration: 120,
    //   date: "2023-07-07 10:00",
    //   resources: "bla bla car",
    //   cancelText: "up to 23 hours",
    //   id: 23,
    // };

    // this.bookingUrl =
    //   window.location.hostname + "/booking-join/" + this.booking.id;
  }

  copyToClipBoard() {
    navigator.clipboard.writeText(this.bookingUrl);
    this.copied = true;
  }
}
