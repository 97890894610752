
import Component from '@modules/vue-class-component';
import { Prop } from '@modules/vue-property-decorator';
import { DateHelper } from '@shared/core/helpers/dateHelper';
import { TranslationsHelper } from '@shared/core/helpers/translationsHelper';
import { LevelProvider, Sport } from '@shared/crm/constants';
import Vue from 'vue';

export interface Level {
  newLevel: number;
  modification: number;
  comments: string | null;
  date: string;
  parentId: number | null;
}

@Component({})
export default class LevelList extends Vue {
  @Prop({ required: true }) sport!: Sport;
  @Prop({ required: true }) provider!: LevelProvider;
  @Prop() linkToProviderUrl?: string | null;
  @Prop({ required: true }) levels!: Level[];
  @Prop({ default: true }) collapsed!: boolean;

  private get title() {
    return `${this.getSportLabel(this.sport)} (${this.getLevelProviderLabel(this.provider)})`;
  }

  private get noLevelText() {
    return this.$t('You have no level yet') + '. '
      + this.$t('Use the Taykus app to find out your level or ask for information at the club');
  }

  private getSportLabel(sport: Sport) {
    return TranslationsHelper.GetSportLabel(sport, this);
  }

  private getLevelProviderLabel(provider: LevelProvider) {
    return TranslationsHelper.GetLevelProviderLabel(provider);
  }

  private getDate(date: string) {
    return DateHelper.toCultureString(date);
  }
}
