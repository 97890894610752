
import Vue from "vue";
import Component from "vue-class-component";
import { LocalStorage } from "../../core/localStorage";
import store from "../../core/store";
import { ShopService } from "../../core/services/shopService";
import { CustomerHelper } from "../../crm/helpers/customerHelper";
import { Prop } from "vue-property-decorator";

interface LanguageConfig {
  language: string;
  description: string,
}

@Component({
  components: {},
})
export default class MenuUpper extends Vue {
  @Prop({ default: false }) cleanLayout;

  currentLanguage: any = "";

  async mounted() {
    this.currentLanguage = LocalStorage.get("lcl");
    this.currentLanguage =
      this.currentLanguage === null || this.currentLanguage === undefined
        ? "es-ES"
        : this.currentLanguage;
  }

  get langImages() : LanguageConfig[] {
    return [
      {
        language: "en-US",
        description: "English", 
      },
      {
        language: "es-ES",
        description: "Español",
      },
      {
        language: "fr-FR",
        description: "Français",
      },
      {
        language: "it-IT",
        description: "Italiano",
      },
    ];
  }

  selectLanguage() {
    LocalStorage.add("lcl", this.currentLanguage);
    this.$router.go(0);
  }

  getClass() {
    let c: any = "nav-link nav-transparent nav-text-small bigger d-f items-center font-medium";
    return c;
  }

  getClassLogin() {
    let c: any =
      "nav-link nav-transparent nav-text-small bigger d-f items-center font-medium";
    return c;
  }

  getClassRegister() {
    return "nav-link nav-transparent nav-text-small bigger d-f items-center font-medium";
  }

  logInOut(login: any) {
    if (store.state.loggedIn !== true) {
      LocalStorage.add("loginModeOn", login);
      store.commit("openLogin", { backUrl: window.location.pathname });
      return;
    }
    new ShopService().removeCart();
    CustomerHelper.logOut();
    window.location.reload();
  }
}
