
import { CrudService } from "../../../core/services/crudService";
import { Settings } from "../../../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { DateHelper } from "../../../core/helpers/dateHelper";
import store from "../../../core/store";
import PopularPosts from "../../../components/popularPosts/popularPosts.vue";
import { CustomerHelper } from "../../helpers/customerHelper";
import { ParentNameActivity } from "../../../crm/constants";
import LoginForm from "../../../components/login/loginForm.vue";
import RegisterForm from "../../../components/login/registerForm.vue";
import Popup from "../../../components/popup/popup.vue";
import DynamicForm, { CustomDynamicFormField } from "../../../components/dynamicForm/dynamicForm.vue";
import { ShopService } from "../../../core/services/shopService";
import { Format } from "../../../core/helpers/formatHelper";
import { LocalStorage } from "../../../core/localStorage";
import { InputType } from "@shared/components/dynamicForm/inputTypes";

@Component({
  components: { PopularPosts, LoginForm, RegisterForm, Popup, DynamicForm },
})
export default class ActivityPage extends Vue {
  activity: any = {};
  error: string = "";
  ok: string = "";
  showDynamicForm: boolean = false;
  showRegister: boolean = false;
  showLogin: boolean = false;
  hasCustomSignUp: boolean = false;
  showLoginRegister: boolean = false;
  customerId: number | null = null;
  partnerCustomerId: number | null = null;
  alreadyIn: boolean = false;
  showCopied: boolean = false;
  showPopupCopiedLink: boolean = false;
  successMsg: string = "";
  activityCustomFormFields: CustomDynamicFormField[] = [];
  hasCategories: boolean = false;

  async mounted() {
    await this.loadData();
    window.scrollTo({ top: 0 });
  }

  async loadData() {
    let cid = CustomerHelper.getStoredCustomer();
    const baseUrl = `${Settings.HostName}/api/publicactivities/${this.$route.params.id}`;
    const url = cid !== undefined ? `${baseUrl}/${cid}` : baseUrl;

    let data = await new CrudService().httpGet(url);
    if (data == null) {
      this.error = this.$t("Crash").toString();
      return;
    }

    this.activity = data.model;
    this.hasCustomSignUp = data.hasCustomSignUp;
    if (this.activity.partnerInscription === true) {
      this.activityCustomFormFields.push({
        name: "partnerCustomerId",
        label: this.$t("Partner"),
        col1: true,
        col2: false,
        required: true,
        value: null,
        columnDef: {
          headerName: this.$t("Partner"),
          field: "partnerCustomerId",
          value: null,
          required: true,
          editInputType: InputType.Lookup,
          floatingFilterComponentParams: {
            fkController: "customers",
            fkLabel: "completeName",
            fkId: "id",
          },
        }
      });
    }
    if (data.alreadyIn === true) {
      this.ok = this.$t("Already In!").toString();
    }

    const categoriesUrl = `${baseUrl}/categories`;
    let categories = await new CrudService().httpGet(categoriesUrl);
    if(!categories.ok) {
      this.error = this.$t("Crash").toString();
      return;
    }
    
    if(categories.model.length) {
      const options = {};
      this.hasCategories = true;

      categories.model.forEach((category: any) => {
        options[category.name] = category.id;
      });
      this.activityCustomFormFields.push({
        name: "activityCategoryId",
        label: this.$t("Category"),
        col1: true,
        col2: false,
        required: true,
        value: null,
        columnDef: {
          headerName: this.$t("Category"),
          field: "activityCategoryId",
          value: null,
          required: true,
          editInputType: InputType.Picklist,
          floatingFilterComponentParams: options,
        }
      });
    }
  }

  singUpClicked() {
    if (this.ok !== "") {
      return;
    }
    this.error = "";
    let cid = CustomerHelper.getStoredCustomer();
    if (cid === undefined) {
      this.showLoginRegister = true;
      return;
    }
    this.customerId = cid;
    this.onCustomerSetted();
  }

  registerDone(data: any) {
    if (data === undefined) {
      return;
    }
    if (data.ok === true) {
      this.customerId = data.id;
      this.tempLogin();
      this.onCustomerSetted();
    }
  }

  loginDone(data: any) {
    if (data === undefined) {
      return;
    }
    if (data.ok === true) {
      this.customerId = data.id;
      this.onCustomerSetted();
    }
  }

  onCustomerSetted() {
    this.showLoginRegister = false;
    this.showLogin = false;
    this.showRegister = false;

    if (this.hasCustomSignUp === false) {
      if (this.activity.partnerInscription || this.hasCategories) {
        this.showDynamicForm = true;
      } else {
        this.directInscription();
      }
      return;
    }
    this.showDynamicForm = true;
  }

  async directInscription() {
    const url = `${Settings.HostName}/api/publicactivities/inscription`;
    let res = await new CrudService().httpPost(url, {
      customerId: this.customerId,
      partnerCustomerId: this.partnerCustomerId,
      id: this.$route.params.id,
    });
    if (res == null) {
      this.error = this.$t("Crash").toString();
      return;
    }

    if (res.ok === false) {
      this.error = res.errorMsg;
      return;
    }

    if (Format.IsNull(res.model)) {
      this.ok = this.$t("Sign Up Done!").toString();
      return;
    }
    for (const sale of res.model.filter((s: any) => s.saleNumber)) {
      this.addToCart(sale);
    }
  }

  dynamicFormSaved(data) {
    if (!data.success === true) {
      return;
    }

    if (Format.IsNull(data.model) || typeof data.model == "number" 
      // No sale was generated
        || data.model.length === 0 || data.model.every((s: any) => !s.saleNumber)) {
      this.showDynamicForm = false;
      this.ok = this.$t("Sign Up Done!").toString();
      return;
    }
    for (const sale of data.model.filter((s: any) => s.saleNumber)) {
      this.addToCart(sale);
    }
  }

  addToCart(sale: any) {
    if (sale.ctk !== null) {
      let in5Mins: any = DateHelper.addMinutes(new Date(), 5);
      LocalStorage.addWithTimeout("ctk", sale.ctk, in5Mins);
      store.state.loggedIn = true;
    }

    sale.title = sale.description;
    new ShopService().addSaleToCart(sale, document.getElementById("btnSignUp"));
    this.$router.push("/checkout");
  }

  tempLogin() {
    let in5Mins: any = DateHelper.addMinutes(new Date(), 5);
    LocalStorage.addWithTimeout(
      "cc",
      JSON.stringify({ id: this.customerId }),
      in5Mins
    );
  }

  getImage(name: string) {
    if (name !== undefined && name !== "" && name !== null) {
      return store.state.settings.Uploads + "/" + name;
    }
    return store.state.settings.Uploads + "/" + store.state.settings.Mainlogo;
  }

  getDateFormatted(dt: string) {
    return DateHelper.formatDate(new Date(dt));
  }

  getParent() {
    return ParentNameActivity;
  }

  dynamicFormLoaded(hasData: boolean) {
    this.hasCustomSignUp = hasData;
  }

  async copyLink() {
    this.copyToClipboard(window.location.href);
    this.successMsg = this.$t("Copied").toString();
    this.showPopupCopiedLink = true;
  }

  copyToClipboard(text) {
    if (!navigator.clipboard) {
      console.log(
        "Yours browser does not allow copy to clipboard" + " " + text
      );
      return;
    }

    navigator.clipboard
      .writeText(text)
      .then(function() {
        console.log("Copied to clipboard");
      })
      .catch(function(err) {
        console.error("Unable to copy", err);
      });
  }
}
