import { LevelProvider, Sport } from "@shared/crm/constants";
import Vue from "vue";

export class TranslationsHelper {

    static GetSportLabel(sport: Sport, vue: Vue): string {
        switch (sport) {
            case Sport.Soccer:
                return vue.$t("Soccer").toString();
            case Sport.Padel:
                return vue.$t("Padel").toString();
            case Sport.Tennis:
                return vue.$t("Tennis").toString();
            case Sport.Basket:
                return vue.$t("Basket").toString();
            case Sport.Golf:
                return vue.$t("Golf").toString();
            case Sport.Pickleball:
                return vue.$t("Pickleball").toString();
            case Sport.Padbol:
                return vue.$t("Padbol").toString();
            case Sport.Racquetball:
                return vue.$t("Racquetball").toString();
            case Sport.Squash:
                return vue.$t("Squash").toString();
            default:
                return sport.toString();
        }
    }

    static GetLevelProviderLabel(provider: LevelProvider): string {
        switch (provider) {
            case LevelProvider.Taykus:
                return "Taykus";
            case LevelProvider.WPR:
                return "World Padel Rating";
        }
    }

}