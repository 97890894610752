
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Popup extends Vue {
  @Prop() errorMsg!: string;
  @Prop() enabled!: boolean;
  @Prop({ default: false }) disableOkButton!: boolean;
  @Prop({ default: false }) forceFullWidth!: boolean;
  @Prop({ default: true }) showOkButton!: boolean;
  @Prop({ default: true }) showCancelButton!: boolean;
  @Prop({ default: "OK" }) textOkButton!: string;
  @Prop({ default: "Cancel" }) textCloseButton!: string;
  @Prop({ default: true}) showBody!: boolean;
  @Prop({ default: false}) isSuccess!: boolean;

  @Watch("enabled")
  enableOkBtn(value) {
    if (value) {
      this.enable();
    }
  }

  @Watch("errorMsg")
  watchErrorMsg(value: string) {
    if (value != null) {
      this.enable();
    }
  }

  @Watch("disableOkButton")
  toggleEnableOkButton(value) {
    if (value === null) {
      return;
    }
    if (value) {
      this.disable();
    } else {
      this.enable();
    }
  }

  @Emit("ok")
  ok() {
    if (this.disableOkButton !== null && this.disableOkButton !== undefined) {
      this.disable();
    }
  }
  @Emit("closed")
  closed() {}

  enable() {
    var okBtn: any = this.$refs.okBtn;
    if (okBtn !== undefined && okBtn !== null) {
      okBtn.disabled = false;
    }
  }

  disable() {
    var okBtn: any = this.$refs.okBtn;
    okBtn.disabled = true;
  }
}
