
import { TenantSettings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import store from "../core/store";

@Component({
  components: {},
} as any)
export default class BlogPage extends Vue {
  locations: any[] | undefined = [];
  error: string = "";
  settings: TenantSettings = {};

  async mounted() {
    this.settings = store.state.settings;
    this.locations = this.settings.RelatedClubs;
  }

  async loadData() {
    window.scrollTo(0, 0);
  }

  getItemListClass(index: number) {
    return index == 1
      ? "page-item pointer li-margin active"
      : "page-item pointer li-margin";
  }
}
