
import { Component, Vue } from "@shared/node_modules/vue-property-decorator";
import SecureLayout from "./layouts/secure.vue";
import InSecureLayout from "./layouts/insecure.vue";
import CleanLayout from "./layouts/clean.vue";
import GlobalError from "@shared/components/globalError/globalError.vue";

@Component({
  components: {
    SecureLayout,
    InSecureLayout,
    CleanLayout,
    GlobalError,
  },
})
export default class App extends Vue {
  layout: string = "";
  reload: number = 0;
  created() {
    this.layout = this.$route.meta?.layout || "insecure";
    this.setDefaultReloadMessage();
  }

  setDefaultReloadMessage() {
    localStorage.setItem(
      window.location.host + "_cz_new_version_reload_message",
      this.$t("The system has been updated").toString()
    );
  }
}
