
import { CrudService } from "@shared/core/services/crudService";
import { Settings } from "@shared/core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { LocalStorage } from "@shared/core/localStorage";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { DateHelper } from "@shared/core/helpers/dateHelper";
import { Format } from "@shared/core/helpers/formatHelper";
import Popup from "@shared/components/popup/popup.vue";
import BookingsFilters from "@shared/bookings/bookingsFilters.vue";
import DatePicker from "@shared/components/datePicker/datePicker.vue";
import { BookingGender, DatePickerMode, IsMobile } from "@shared/core/constants";
import { PriceDescriptor } from "@shared/core/types/PriceDescriptor";
import { Global } from "@shared/core/helpers/global";
import SubmitButton from "@shared/components/submitButton/submitButton.vue";
import store from "@shared/core/store";
import CreateMatchPopup from "./createMatchPopup.vue";

interface NoOnlineBlock {
  start: string;
  resourceId: number;
  resourceName: string;
  duration: number;
  isVirtual: boolean;
}

interface Resource {
  id: number;
  name: string;
  timeTable: {
    start: string, priceByDuration: PriceDescriptor[]
  }[];
}

@Component({
  components: {
    VueSlickCarousel,
    Popup,
    DatePicker,
    BookingsFilters,
    SubmitButton,
    CreateMatchPopup,
  },
})
export default class BookingsPage extends Vue {
  errorMsg: string = "";
  data: any[] = [];
  dbData: any[] = [];

  date: Date | string = "";
  bookingTypeSelected: any | null = null;
  resourceTypeId: number | null = null;
  selectedPrice: PriceDescriptor | null = null;
  bookingSelected: any = {};
  searchKey: string = "";

  closeFilter: number = 0;
  openFilter: number = 0;
  filterButton: boolean = false;

  dbBookingTypes: any[] = [];
  bookingTypes: any[] = [];
  durations: any[] = [];
  anticipationText: string = "";
  anticipationMins: any = null;
  cancelText: string = "";

  showOtherResources: boolean = false;
  otherResourceTypes: any[] | null = null;
  dataByResource: any | null;
  otherResourceOptionIndex = 0;

  blocks: any[] = [];

  carouselSettings: any = null;

  availableDates: any[] = [];
  selectedDate: any = null;
  selectedDuration: any = null;

  windowWidth: number = window.innerWidth;

  private get isSm(){
    return this.windowWidth < 768;
  }
  showMatchPopup: boolean = false;
  matchOptions: { duration: number; resource: Resource; start: string; noOnlineBlocks: NoOnlineBlock[] | null; }|null = null;

  loadCarrouselSettings() {
    this.carouselSettings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 8,
      slidesToScroll: 8,
      arrows: true,
      centerMode:
        this.dbData.length <= 8 && this.dbData.length != 0 ? true : false,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            //dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
            centerMode:false,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: false,
            arrows: false,
          },
        },
      ],
    };
  }

  async mounted() {
    let url = `${Settings.HostName}/api/publicbookings/filters`;
    if (!Format.IsNull(this.$route.params.bookingTypeId)) {
      url += `/${this.$route.params.bookingTypeId}`;
    }

    let res = await new CrudService().httpGet(url);
    if (res == null || res.ok === false) {
      console.error("Crash getting filters");
      this.errorMsg = this.$t("Crash").toString();
      return;
    }

    this.dbBookingTypes = res.model.bookingTypes;
    this.bookingTypes = res.model.bookingTypes;
    // this.resourceTypes = res.model.resourceTypes;
    this.anticipationText = res.model.anticipationText;
    this.anticipationMins = res.model.anticipationMins;
    this.cancelText = res.model.cancelText;
    this.setUpBookingTypeFilter();
    this.loadMoreDates();
    this.selectedDate = this.availableDates[0];

    await this.loadData();

    document.addEventListener("click", this.doNotHideFilter);

    if (this.$store.state.loggedIn === false) {
      this.$store.commit("openLogin", { backUrl: this.$route.path });
    }

    const domprev: any = document.getElementsByClassName("slick-prev");
    if (domprev !== null && domprev !== undefined && domprev.length > 0) {
      domprev[0].innerHTML = "";
    }

    const domnext: any = document.getElementsByClassName("slick-next");
    if (domnext !== null && domnext !== undefined && domnext.length > 0) {
      domnext[0].innerHTML = "";
    }
    window.addEventListener("resize", this.updateWindowWidth);
    
    const datePickerRef = this.$refs.datePicker as any;
    if(datePickerRef.date == null){
      datePickerRef.date = DateHelper.formatDate(new Date()) 
    }
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowWidth);
  }

  handleScroll() {
    const container = this.$refs.dateSelector as HTMLElement;
    if (container.scrollLeft + container.clientWidth >= container.scrollWidth - 10) {
      this.loadMoreDates(); // Cargar más fechas
    }
  }

  loadMoreDates() {
    const totalDays = this.anticipationMins == null ? 366 : Math.ceil(this.anticipationMins / 1440);
    const today = new Date();
    const maxDate = new Date(today);
    maxDate.setDate(today.getDate() + totalDays); // Última fecha permitida

    let lastDate = this.availableDates.length > 0
      ? new Date(this.availableDates[this.availableDates.length - 1].fullDate)
      : today;

    const locale = Vue.prototype.$culture;

    while (lastDate < maxDate) {
      let newDate = new Date(lastDate);
      const newFullDate = newDate.toISOString().split('T')[0];

      if (!this.availableDates.some(date => date.fullDate === newFullDate)) {
        this.availableDates.push({
          weekday: newDate.toLocaleString(locale, { weekday: 'short' }).toUpperCase(),
          day: newDate.getDate().toString().padStart(2, '0'),
          month: newDate.toLocaleString(locale, { month: 'short' }).toUpperCase(),
          fullDate: newFullDate
        });
      }
      lastDate.setDate(lastDate.getDate() + 1);
    }
  }

  prevSlide() {
    const carouselLeft = this.$refs.carousel as VueSlickCarousel | undefined;
    const prevButton = this.$refs.prevButton as HTMLElement;
    if (carouselLeft && typeof carouselLeft.prev === "function") {
      carouselLeft.prev();
    }
  }
  nextSlide() {
    const carouselR = this.$refs.carousel as VueSlickCarousel | undefined;
    if (carouselR && typeof carouselR.next === "function") {
      carouselR.next();
    }
  }



  updateWindowWidth() {
    this.windowWidth = window.innerWidth;

    const domprev: any = document.getElementsByClassName("slick-prev");
    if (domprev !== null && domprev !== undefined && domprev.length > 0) {
      domprev[0].innerHTML = "";
    }

    const domnext: any = document.getElementsByClassName("slick-next");
    if (domnext !== null && domnext !== undefined && domnext.length > 0) {
      domnext[0].innerHTML = "";
    }
  }

  /// the controls hides on global click
  doNotHideFilter() {
    let domFilterClose = document.getElementById("dom-filter-close");
    domFilterClose?.classList.add("active");
  }

  closeFilters() {
    this.closeFilter++; // to trigger watch
  }

  openFilters() {
    this.openFilter++; // to trigger watch
  }

  setUpBookingTypeFilter() {
    if (this.bookingTypes.length === 1) {
      const bType = this.dbBookingTypes[0];
      this.bookingTypeSelected = bType;
      return;
    }

    this.filterButton = true;
    if (this.$route.params.bookingTypeId !== undefined) {
      const bookingTypeId = Number(this.$route.params.bookingTypeId);
      this.bookingTypeSelected = this.dbBookingTypes.find(
        (y) => y.id == bookingTypeId
      );
      return;
    }
    setTimeout(this.openFilters, 100);
  }

  async selectBookingType(bType: any) {
    if (
      bType.byPlace === true &&
      (Format.IsNull(bType.allowCreateBookByPlace) ||
        bType.allowCreateBookByPlace === false)
    ) {
      return this.$router.push("/bookings-byplace/" + bType.id);
    }
    if (this.bookingTypeSelected?.id === bType.id) {
      return;
    }

    this.bookingTypeSelected = bType;

    if (this.bookingTypeSelected !== null) {
      await this.loadData();
    }
  }

  searchBookingType() {
    if (this.searchKey === "") {
      this.bookingTypes = this.dbBookingTypes;
    }

    const res = this.bookingTypes.filter(
      (y) => y.name.toLowerCase().indexOf(this.searchKey.toLowerCase()) !== -1
    );

    this.bookingTypes = res;
  }

  async loadData() {
    this.errorMsg = "";

    if (this.dbBookingTypes.length === 0) {
      return;
    }

    if (!this.date) {
      var dt = new Date();
      this.date = `${dt.getFullYear()}-${
        dt.getMonth() + 1
      }-${dt.getDate()} 00:00:00`;
    }
    if (
      this.bookingTypeSelected === null ||
      this.bookingTypeSelected === undefined
    ) {
      return;
    }

    let cid = 0;
    let sid = LocalStorage.getWithTimeout("cc");
    if (sid !== undefined && sid !== null) {
      cid = JSON.parse(sid).id;
    }

    this.data = [];
    const url = `${Settings.HostName}/api/publicbookings/list`;
    Global.TriggerDomId = "loader";

    const res = await new CrudService().httpPost(url, {
      date: this.date == "" ? store.getters.localNow : this.date,
      bookingTypeId: this.bookingTypeSelected.id,
      customerId: cid === 0 ? null : cid,
      // resourceTypeId: this.resourceTypeId,
    });

    if (res == null) {
      console.error("Crash getting bookings");
      return;
    }

    if (
      res.ok === false &&
      res.errorMsg !== undefined &&
      res.errorMsg !== null
    ) {
      this.errorMsg = res.errorMsg;
      return;
    }

    if (res.model.list.length === 0) {
      this.errorMsg = res.model.errorMsg;
      this.data.length = 0;
      return;
    }

    this.data = res.model.list;
    this.otherResourceTypes = res.model.multipleResources;
    this.dbData = res.model.list;

    this.setUpDurations();
    this.loadCarrouselSettings();
  }

  setUpDurations() {
    this.durations = [];
    for (const resource of this.data) {
      for (const timetable of resource.timeTable) {
        for (const duration of timetable.durations) {
          if (this.durations.find((y) => y === duration) === undefined) {
            this.durations.push(duration);
          }
        }
      }
    }
    if (this.durations.length === 1) {
      this.durations = [];
    }
  }

  private async onCreateMatch(options: {gender: BookingGender, competitive: boolean, matchRange: Number[]}) {
    this.showMatchPopup = false;
    this.onConfirmBookingDetails(
      this.matchOptions!.duration,
      this.matchOptions!.resource,
      this.matchOptions!.start,
      this.matchOptions!.noOnlineBlocks,
      options.gender,
      options.competitive,
      options.matchRange
    );
  }

  private async selectDuration(
    e: Event,
    duration: number,
    resource: Resource,
    start: string,
    accordionToggleOptions?: {key: number, index: number},
    noOnlineBlocks: NoOnlineBlock[]|null = null
  ) {
    e.preventDefault();

    // Check if user is logged in
    if (this.$store.state.loggedIn === false) {
      this.$store.commit("openLogin", { backUrl: this.$route.path });
      if (accordionToggleOptions != null) {
        this.toogle(accordionToggleOptions.key, accordionToggleOptions.index, null, 0);
      }
      return;
    }

    // Show popup if booking type is by place and allows creation
    if (this.bookingTypeSelected.byPlace && this.bookingTypeSelected.allowCreateBookByPlace) {
      this.showMatchPopup = true;
      this.matchOptions = {
        duration, resource, start, noOnlineBlocks
      };
      return;
    }

    this.onConfirmBookingDetails(duration, resource, start, noOnlineBlocks);
  }

  private async onConfirmBookingDetails(
    duration: number,
    resource: Resource,
    start: string,
    noOnlineBlocks: NoOnlineBlock[]|null,
    gender?: BookingGender,
    competitive: boolean = false,
    matchRange?: Number[]
  ) {
    this.blocks.push({
      duration: duration,
      resourceId: resource.id,
      resourceName: resource.name,
      startDate: start,
      sstart: DateHelper.toCultureString(start),
      noOnline: false,
    });

    if (noOnlineBlocks !== null) {
      for (const noOnlineBlock of noOnlineBlocks) {
        this.blocks.push({
          duration: noOnlineBlock.duration,
          resourceId: noOnlineBlock.resourceId,
          resourceName: noOnlineBlock.resourceName,
          startDate: noOnlineBlock.start,
          sstart: DateHelper.toCultureString(noOnlineBlock.start),
          noOnline: true,
        });
      }
    }

    this.errorMsg = "";
    this.closeFilters();

    if (resource.timeTable !== undefined) {
      const timetable = resource.timeTable.find((y) => y.start === start);
      this.selectedPrice = timetable?.priceByDuration[
        duration
      ] as PriceDescriptor;
    }

    const othersLength =
      this.showOtherResources !== undefined && this.otherResourceTypes !== null
        ? this.otherResourceTypes.length
        : 0;

    if (othersLength != 0 && this.otherResourceOptionIndex < othersLength) {
      // show popup with other related resources options
      await this.loadRelatedResourceOptions(this.blocks[0]);
      this.showOtherResources = true;
      return;
    }
    this.showOtherResources = false;

    this.goToPay(gender, competitive, matchRange);
  }

  async loadRelatedResourceOptions(block: any) {
    if (this.otherResourceTypes === null) {
      return;
    }

    let cid = 0;
    let sid = LocalStorage.getWithTimeout("cc");
    if (sid !== undefined && sid !== null) {
      cid = JSON.parse(sid).id;
    }

    const id = this.otherResourceTypes[this.otherResourceOptionIndex];
    const url = `${Settings.HostName}/api/publicbookings/date-availability`;

    const res = await new CrudService().httpPost(url, {
      date: block.startDate,
      customerId: cid === 0 ? null : cid,
      bookingTypeResourceId: id,
    });

    if (res == null) {
      console.error("Crash getting date-availability");
      return;
    }

    if (res.ok === false || res.model === null || res.model.length === 0) {
      this.errorMsg = this.$t(
        "Sorry nothing found Try with other filters"
      ).toString();
      return;
    }
    this.otherResourceOptionIndex += 1;
    this.dataByResource = res.model;
  }

  goToPay(gender?: BookingGender, competitive?: boolean, matchRange?: Number[]) {
    this.errorMsg = "";
    let cid = 0;
    let sid = LocalStorage.getWithTimeout("cc");
    if (sid !== undefined && sid !== null) {
      cid = JSON.parse(sid).id;
    }

    if (cid === 0 || cid === null) {
      this.errorMsg = this.$t("Please login or register").toString();
      return;
    }

    if (this.blocks.length === 0) {
      return;
    }

    let booking: any = {};
    booking.customerId = cid;

    booking.bookingTypeId = this.bookingTypeSelected.id;
    booking.bookingTypeName = this.bookingTypeSelected.name;
    booking.maxParticipants = this.bookingTypeSelected.maxParticipants;
    booking.isValidated = false;
    booking.sendCommunications = false;
    booking.origin = this.getOrigin();
    booking.bookingByPlace = this.bookingTypeSelected.byPlace;
    booking.cancelText = this.cancelText;
    booking.price = this.selectedPrice;
    booking.date = this.blocks[0].startDate;
    booking.duration = this.blocks[0].duration;
    booking.gender = gender;
    booking.modifyLevel = competitive;
    const resources = this.blocks.filter((y) => y.noOnline !== true);
    booking.resourceName = resources.map((y) => y.resourceName).join(" ");

    if(matchRange != null && matchRange.length == 2){
      booking.minLevel = matchRange[0];
      booking.maxLevel = matchRange[1];
    }

    if (booking.customerId !== null) {
      for (let i = 0; i < this.blocks.length; i++) {
        const block = this.blocks[i];
        block.customerId = booking.customerId;
      }
    }

    const query = {
      booking: booking,
      blocks: this.blocks,
      isMatch: this.bookingTypeSelected.byPlace && this.bookingTypeSelected.allowCreateBookByPlace,
    };

    this.$router.push({
      path: "/checkout-booking",
      query: { pl: btoa(JSON.stringify(query)) },
    });
  }

  getOrigin() {
    return Format.getOrigin();
  }

  async selectDate(date) {
      this.selectedDate = date;
      this.date = date.fullDate;
      await this.loadData();
  }

  async filterByDate(date: any) {
    this.date = date.systemStrDate;
    await this.loadData();
  }
  selectDurationMobile(e: any, duration: number) {
      this.selectedDuration = duration === this.selectedDuration ? null : duration;
      this.filterByDuration(e, this.selectedDuration);
  }

  filterByDuration(e: any, duration: number | null) {
    e.preventDefault();
    this.data = JSON.parse(JSON.stringify(this.dbData))

    if(duration == null) {
      return;
    }

    for (const resource of this.data) {
      for (const timetable of resource.timeTable) {
        if (timetable.durations.find((y) => y === duration) === undefined) {
          resource.timeTable = resource.timeTable.filter(
            (y) => y !== timetable
          );
        }
        // else{
        //   timetable.durations = timetable.durations.filter(
        //     (y) => y === duration
        //   );
        // }
      }
    }
  }

  async toogle(key: number, index: number, timetable: any, resourceId: number) {
    const collapse: any = document.getElementById(
      `id_accordion${key}${index}Collapse1`
    );
    const accordion: any = document.getElementById(
      `#accordion${key}${index}Collapse1`
    );
    const title: any = document.getElementById(
      `accordion${key}${index}Heading1`
    );

    if (collapse === null || accordion === null) {
      return;
    }

    if (collapse.classList.contains("collapsed")) {
      const res = await this.getPrice(key, index, timetable, resourceId);
      if (res === false) {
        return;
      }
      collapse.setAttribute("aria-expanded", true);
      collapse.classList.remove("collapsed");
      accordion.classList.add("show");
      title.parentNode.classList.add("show");
    } else {
      collapse.setAttribute("aria-expanded", false);
      collapse.classList.add("collapsed");
      accordion.classList.remove("show");
      title.parentNode.classList.remove("show");
    }
  }

  async getPrice(
    key: number,
    index: number,
    timetable: any,
    resourceId: number
  ) {
    for (const duration of timetable.durations) {
      let spanPrice: any = document.getElementById(
        `id_price${key}${index}_${duration}`
      );

      let price = timetable.priceByDuration[duration] as PriceDescriptor;
      if (price !== null && price !== undefined) {
        spanPrice.innerHTML = Format.formatCurrency(price.amount.toString());
        continue;
      }

      let cid = 0;
      let sid = LocalStorage.getWithTimeout("cc");
      if (sid !== undefined && sid !== null) {
        cid = JSON.parse(sid).id;
      }

      const url = `${Settings.HostName}/api/publicbookings/loadprice`;
      Global.TriggerDomId = "`id_price${key}${index}_${duration}`";
      const res = await new CrudService().httpPost(url, {
        startDate: timetable.start,
        bookingTypeId: this.bookingTypeSelected.id,
        duration: duration,
        customerId: cid === 0 ? null : cid,
        resourceId: resourceId,
      });

      if (res.ok === false) {
        return false;
      }

      var displayPrice = store.state.settings.PriceWithTaxes
        ? res.price.price
        : res.price.netPrice;
      timetable.priceByDuration[duration] = {
        amount: displayPrice,
        isSpecialPrice: res.price.isSpecialPrice,
      };
      spanPrice.innerHTML = Format.formatCurrency(displayPrice);
    }
    return true;
  }

  beautifyHour(hour: string) {
    if (hour.indexOf("AM") !== -1 || hour.indexOf("PM") !== -1) {
      const splited = hour.split(" ");
      return `${
        splited[0]
      } <span class='font-normal'>${splited[1].toLocaleLowerCase()}</span>`;
    }
    return hour;
  }

  datePickerMode() {
    return DatePickerMode.Date;
  }

  getEnableUpTo() {
    if (Format.IsNull(this.anticipationMins)) {
      return null;
    }
    return DateHelper.addMinutes(store.getters.localNow, this.anticipationMins);
  }

  getResourceImage(image: string) {
    if (image !== undefined && image !== "" && image !== null) {
      return store.state.settings.Uploads + "/" + image;
    }
    return store.state.settings.Uploads + "/" + store.state.settings.Mainlogo;
  }

  goToBottomPage(e: any) {
    this.closeFilters();
    e.preventDefault();
    window.scrollTo(0, document.body.scrollHeight);
  }

  isMobile() {
    return IsMobile;
  }

  toggleDisabledButtons(slick: any) {
    const prevButton = this.$refs.prevButton as HTMLElement;
    const nextButton = this.$refs.nextButton as HTMLElement;

    // Si no hay más elementos a la izquierda (primer slide)
    if (slick.currentSlide === 0) {
      prevButton.classList.add('slick-disabled');
    } else {
      prevButton.classList.remove('slick-disabled');
    }

    // Si no hay más elementos a la derecha (último slide)
    if (slick.currentSlide === slick.slideCount - 1) {
      nextButton.classList.add('slick-disabled');
    } else {
      nextButton.classList.remove('slick-disabled');
    }
  }
}
