
import { CrudService } from "../../../shared/core/services/crudService";
import { Settings } from "../../../shared/core/services/configService";
import Vue from "../../../shared/node_modules/vue";
import Component from "../../../shared/node_modules/vue-class-component";
import { LocalStorage } from "../../../shared/core/localStorage";
import { LevelProvider, Sport } from "@shared/crm/constants";
import LevelList, { Level } from "@shared/components/levels/levelList.vue";

interface LevelsBySport {
  sport: Sport;
  provider: LevelProvider;
  levels: Level[];
}

@Component({
  components: {
    LevelList
  },
})
export default class LevelsPage extends Vue {
  private data: LevelsBySport[];
  private errorMsg: string;
  private customerId: number;
  private isDataLoading: boolean;
  private linkToProvidersUrls: Map<LevelProvider, string>;

  constructor() {
    super();
    this.data = [];
    this.errorMsg = "";
    this.customerId = 0;
    this.isDataLoading = false;
    this.linkToProvidersUrls = new Map<LevelProvider, string>();
  }

  async mounted() {
    try {
      this.customerId = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
    } catch {
      console.error("not customer logged found");
      return;
    }
    await this.loadData();
  }

  private async loadData() {
    this.isDataLoading = true;
    this.data = [];
    this.errorMsg = "";
    const url = `${Settings.HostName}/api/publicbookings/levels/${this.customerId}`;
    const data = await new CrudService().httpGet(url);
    if (data == null || data.ok === false) {
        this.errorMsg = data.errorMsg;
        this.isDataLoading = false;
        return;
    }
    const model = data.model as LevelsBySport[];
    this.linkToProvidersUrls = new Map<LevelProvider, string>();
    for (const item of model) {
      if (item.levels == null || item.levels.length == 0) {
        if (!this.linkToProvidersUrls.has(item.provider)) {
          const link = await this.getLinkToProviderUrl(item.provider);
          if (link != null) {
            this.linkToProvidersUrls.set(item.provider, link);
          }
        }
      }
    }

    this.data = data.model;
    this.isDataLoading = false;
  }

  private async getLinkToProviderUrl(provider: LevelProvider): Promise<string|null> {
    let url = `${Settings.HostName}/api/publicbookings/levels/${this.customerId}/provider/${provider}/link`;
    let data = await new CrudService().httpGet(url);
    if (data == null || data.ok === false) {
      return null;
    }
    return data.model;
  }
}
